import React, { useState } from "react";
import { Box, Button, Flex, Stack, useTheme } from "@chakra-ui/react";
import Sales from "./components/Sales";
import Sdc from "./components/Sdc";
import Strike from "./components/Strike";
import { useSupplierRetailer } from "../../../wrappers/SupplierRetailerContext";

const Delete = () => {
  const [activeButton, setActiveButton] = useState("Sales");
  const theme = useTheme();
  const { selectedRetailer, selectedSupplier } = useSupplierRetailer();

  const renderComponent = () => {
    switch (activeButton) {
      case "Sales":
        return <Sales supplier={selectedSupplier} retailer={selectedRetailer}/>;
      case "SDC":
        return <Sdc supplier={selectedSupplier} retailer={selectedRetailer}/>;
      case "Strike":
        return <Strike supplier={selectedSupplier} retailer={selectedRetailer}/>;
      default:
        return null;
    }
  };

  return (
    <Box mt={16}>
      <Flex justify="center" mb={4}>
        <Stack direction="row" spacing={4}>
          <Button onClick={() => setActiveButton("Sales")} variant={activeButton === "Sales" ? "solid" : "outline"} borderColor={activeButton === "Sales" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Sales
          </Button>
          <Button onClick={() => setActiveButton("SDC")} variant={activeButton === "SDC" ? "solid" : "outline"} borderColor={activeButton === "SDC" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            SDC
          </Button>
          <Button onClick={() => setActiveButton("Strike")} variant={activeButton === "Strike" ? "solid" : "outline"} borderColor={activeButton === "Strike" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Strike Rate
          </Button>
        </Stack>
      </Flex>
      <Flex justifyContent="center">
        <Box width="1100px">{renderComponent()}</Box>
      </Flex>
    </Box>
  );
};

export default Delete;
