import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSupplierRetailer } from "./SupplierRetailerContext";
import { usePageAccess } from "./PageAccessContext";

const AuthContext = createContext();

export const useAuthentication = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const { resetContext: resetSupplierRetailerContext } = useSupplierRetailer();
  const { resetContext: resetPageAccessContext } = usePageAccess();
  const signIn = () => {
    setIsAuthenticated(true);
    // resetSupplierRetailerContext();//@mike
    // resetPageAccessContext();//@mike
  };

  const signOut = () => {
    setIsAuthenticated(false);
    resetSupplierRetailerContext();
    resetPageAccessContext();
    localStorage.clear();
    navigate("/signin");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};