import React, { useState, useEffect } from "react";
//import axios from "axios";
import { Box, Heading, Flex } from "@chakra-ui/react";

import Exports from "./components/Exports";
import Table1 from "./components/Table1";
import Top10A from "./components/Top10A";
import Top10B from "./components/Top10B";
import Top10C from "./components/Top10C";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import Chart4 from "./components/Chart4";
import Chart5 from "./components/Chart5";
import Chart6 from "./components/Chart6";

import { useSupplierRetailer } from "../../wrappers/SupplierRetailerContext";

const Sales = () => {
  const { selectedRetailer, selectedSupplier } = useSupplierRetailer();
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   // Simulate a delay for fetching data
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, [selectedRetailer, selectedSupplier]);

  return (
    <Box overflowX="auto" overflowY="hidden">
      <Heading size="lg" mb={4}>
        Sales Report
      </Heading>
      {loading ? (
        <Box>Loading...</Box>
      ) : (
        <>
          {/* <Box position="fixed" right="5px" zIndex={2000} top="5px">
          <Exports supplier={selectedSupplier} retailer={selectedRetailer} />
          </Box> */}
          <Box maxHeight="700px" overflowY="auto" maxWidth="97vw" >
            <Table1 supplier={selectedSupplier} retailer={selectedRetailer} />
          </Box>
          <Box p={4}>
            <Flex direction={{ base: "column", lg: "row" }} wrap="wrap" justify="space-between" align="flex-start" gap={4}>
              <Box flex="1" minW="300px">
                <Top10A supplier={selectedSupplier} retailer={selectedRetailer} />
              </Box>
              <Box flex="1" minW="300px">
                <Top10B supplier={selectedSupplier} retailer={selectedRetailer} />
              </Box>
              <Box flex="1" minW="300px">
                <Top10C supplier={selectedSupplier} retailer={selectedRetailer} />
              </Box>
            </Flex>
          </Box>
          <Box p={4} mb={8}>
            <Flex direction={{ base: "column", lg: "row" }} wrap="wrap" justify="space-between" align="flex-start" gap={4}>
              <Box flex="1" minW="300px" height="30vh">
                <Chart1 supplier={selectedSupplier} retailer={selectedRetailer} />
              </Box>
              <Box flex="1" minW="300px" height="30vh">
                <Chart2 supplier={selectedSupplier} retailer={selectedRetailer} />
              </Box>
            </Flex>
          </Box>
          <Box p={4} mb={8}>
            <Box flex="1" minW="300px" height="30vh">
              <Chart4 supplier={selectedSupplier} retailer={selectedRetailer} />
            </Box>
          </Box>
          <Box p={4} mb={8}>
            <Box flex="1" minW="300px" height="30vh">
              <Chart5 supplier={selectedSupplier} retailer={selectedRetailer} />
            </Box>
          </Box>
          <Box p={4} mb={2}>
            <Box flex="1" minW="300px" height="30vh" maxH="30vh">
              <Chart6 supplier={selectedSupplier} retailer={selectedRetailer} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Sales;
