import React, { useEffect, useState } from "react";
import { Box, Heading, Flex, Spacer, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useSupplierRetailer } from "../../wrappers/SupplierRetailerContext";
import TableWeekly from "./components/TableWeekly";
import TableMonthly from "./components/TableMonthly";
import TableReason from "./components/TableReason";
import TableComment from "./components/TableComment";


const StrikeRate = () => {
  const [ddStrikeRate, setDdStrikeRate] = useState([]);
  const [weekEndingDates, setWeekEndingDates] = useState([]);
  const toast = useToast();

  const { selectedRetailer, selectedSupplier } = useSupplierRetailer();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [selectedRetailer, selectedSupplier]);

  const loadData = async () => {
    await fetchDdStrikeRate();
    await fetchWeekEndingDates();
    setLoading(false);
  };

  const fetchDdStrikeRate = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_strikeRate/get-dd-strike-rate`, {});
      setDdStrikeRate(res.data.data);
    } catch (error) {
      console.error("Error fetching strike rate data:", error);
    }
  };

  const fetchWeekEndingDates = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_strikeRate/week-ending-date`, {
        retailer: selectedRetailer,
      });
      setWeekEndingDates(res.data.data);
    } catch (error) {
      console.error("Error fetching week ending dates:", error);
    }
  };

  return (
    <Box>
      <Heading>Strike Rate</Heading>
      <Flex direction="column" mt={4}>
          {!loading && weekEndingDates.length > 0 ? <TableComment selectedRetailer={selectedRetailer} selectedSupplier={selectedSupplier} weekEndingDates={weekEndingDates} ddStrikeRate={ddStrikeRate} /> : <Box>Loading...</Box>}
        <Flex width="100%" mt={4}>
          <TableWeekly selectedRetailer={selectedRetailer} selectedSupplier={selectedSupplier} />
          <TableMonthly selectedRetailer={selectedRetailer} selectedSupplier={selectedSupplier} />
          <TableReason selectedRetailer={selectedRetailer} selectedSupplier={selectedSupplier} ddStrikeRate={ddStrikeRate} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default StrikeRate;
