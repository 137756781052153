import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, useToast, Box, Heading, Flex, Select, Input, Button, Spinner, IconButton, useTheme } from "@chakra-ui/react";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import AddRegionStore from "./AddRegionStore"; // make sure the path is correct

const RegionsTable = () => {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState({ site_code: "", region: "" });

  const [retailers, setRetailers] = useState(["", "PNP", "CHK", "DIS", "CLI", "MAK"]);
  const [selectedRetailer, setSelectedRetailer] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const theme = useTheme();

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-store-regions`, { page, limit: 50, search: searchTerm, selectedRegion, selectedRetailer });
      setData(response.data.data);
      setFilteredData(response.data.data);
      setRegions(response.data.regions);
      //console.log(response.data.regions);
      setTotalPages(Math.ceil(response.data.total / 50));
      setCurrentPage(page);
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Failed to load data",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchData(1);
  }, [selectedRegion]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData(1);
  }, [selectedRetailer]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData(1);
  }, [searchTerm]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, searchTerm]);

  const filterData = () => {
    let filtered = data;
    if (selectedRegion) {
      filtered = filtered.filter((store) => store.region === selectedRegion);
    }
    if (searchTerm) {
      filtered = filtered.filter((store) => store.site_code.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    setFilteredData(filtered);
  };

  const deleteStore = async (site_code, site_description) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/delete-region-store`, {
        site_code,
        site_description,
      });
      toast({
        title: "Store deleted",
        description: "The store has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchData(currentPage);
    } catch (error) {
      toast({
        title: "Failed to delete store",
        description: "An error occurred while deleting the store.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
    setEditRowData({
      site_code: filteredData[index].site_code,
      region: filteredData[index].region,
      site_description: filteredData[index].site_description,
      retailer: filteredData[index].retailer,
    });
  };

  const handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-region-store`, {
        site_code: editRowData.site_code,
        region: editRowData.region,
        site_description: editRowData.site_description,
        retailer: editRowData.retailer,
      });
      toast({
        title: "Store updated",
        description: "The store has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditRowIndex(null);
      fetchData(currentPage);
    } catch (error) {
      toast({
        title: "Failed to update store",
        description: "An error occurred while updating the store.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      }).format(date);
    } catch (error) {
      console.log(dateString, error);
      return "err";
    }
  };

  return (
    <Flex minWidth="75vw">
      <Box flex="1" maxHeight="700px" overflowY="auto">
        <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
          Store Regions
        </Heading>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
            <Thead>
              <Tr>
                <Th>Site Code</Th>
                <Th>Site Name</Th>
                <Th>Region</Th>
                <Th>Retailer</Th>
                <Th>Created Date</Th>
                <Th>Delete</Th>
                <Th>Edit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData.map((row, index) => (
                <Tr key={index}>
                  <Td>{row.site_code}</Td>

                  <Td>{editRowIndex === index ? <Input value={editRowData.site_description} onChange={(e) => setEditRowData({ ...editRowData, site_description: e.target.value })} /> : row.site_description}</Td>

                  <Td>{editRowIndex === index ? <Input value={editRowData.region} onChange={(e) => setEditRowData({ ...editRowData, region: e.target.value })} /> : row.region}</Td>

                  <Td>
                    {editRowIndex === index ? (
                      <Select value={editRowData.retailer} onChange={(e) => setEditRowData({ ...editRowData, retailer: e.target.value })}>
                        {retailers.map((retailer, idx) => (
                          <option key={idx} value={retailer}>
                            {retailer}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      row.retailer
                    )}
                  </Td>

                  <Td>{formatDate(row.createddate)}</Td>
                  <Td>
                    <IconButton icon={<FaTrash />} onClick={() => deleteStore(row.site_code, row.site_description)} colorScheme="red" variant="ghost" />{" "}
                  </Td>
                  <Td>{editRowIndex === index ? <IconButton icon={<FaSave />} onClick={handleSave} colorScheme="green" variant="ghost" /> : <IconButton icon={<FaEdit />} onClick={() => handleEdit(index)} colorScheme="blue" variant="ghost" />}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>

      <Flex flexDirection={"column"} gap={"4px"}>
        <Box ml={4}>
          <Select placeholder="Select Region" mb={4} value={selectedRegion} onChange={(e) => setSelectedRegion(e.target.value)}>
            {regions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </Select>
          <Select placeholder="Select Retailer" mb={4} value={selectedRetailer} onChange={(e) => setSelectedRetailer(e.target.value)}>
            {retailers.map((retailer, index) => (
              <option key={index} value={retailer}>
                {retailer}
              </option>
            ))}
          </Select>
          <Input placeholder="Search" mb={4} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
            Add New Store
          </Button>
        </Box>
        <Box mt={4} display="flex" justifyContent="center">
          <Button onClick={() => fetchData(currentPage - 1)} isDisabled={currentPage === 1} mr={2}>
            Previous
          </Button>
          <Button onClick={() => fetchData(currentPage + 1)} isDisabled={currentPage === totalPages}>
            Next
          </Button>
        </Box>
      </Flex>

      <AddRegionStore isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} regions={regions} retailers={retailers} refreshData={fetchData} />
    </Flex>
  );
};

export default RegionsTable;
