import React, { useState } from "react";
import { Box, Input, Textarea, Button, FormControl, FormLabel, useToast, Spinner,Text,Heading } from "@chakra-ui/react";
import axios from "axios";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellnr, setCellnr] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateCellnr = (cellnr) => {
    return cellnr.length === 10 && /^\d+$/.test(cellnr);
  };

  const handleSubmit = async () => {
    let newErrors = {};
    if (!name || !message || (!email && !cellnr)) {
        newErrors = {
            ...newErrors,
            name: !name,
            email: !email && !cellnr,
            cellnr: !email && !cellnr,
            message: !message,
          };
      toast({
        title: "Validation Error",
        description: "Please fill in the name, message, and either a valid email or cell number.",
        status: "error",
        duration: 3500,
        isClosable: true,
      });
      setErrors(newErrors);
      return;
    }
    if (email && !validateEmail(email)) {
        newErrors.email = true;
      toast({
        title: "Validation Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 3500,
        isClosable: true,
      });
      setErrors(newErrors);
      return;
    }
    if (cellnr && !validateCellnr(cellnr)) {
        newErrors.cellnr = true;
      toast({
        title: "Validation Error",
        description: "Please enter a valid 10-digit cell number.",
        status: "error",
        duration: 3500,
        isClosable: true,
      });
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    setErrors({});
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/marketing/contact-us`, {
        name,
        email,
        message,
        cellnr,
      });
      const { status, title, description, data } = res.data;
      toast({
        title: title,
        description: description,
        status: status,
        duration: 3500,
        isClosable: true,
      });
      setIsLoading(false);
      setName("");
      setEmail("");
      setCellnr("");
      setMessage("");
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Technical Error",
        description: "Something went wrong! please try again later.",
        status: "error",
        duration: 3500,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="600px" mx="auto" mt="10" boxShadow="lg" borderRadius="md" p={2}>
      <Heading as="h2" size="xl" mb="4">Send us an enquiry</Heading>
      <Text fontSize="lg" mb="4">
         Get in contact via email or WhatsApp to set up a demo,<br />
          enquire about pricing, <br />
          or learn more about how the app works.<br />
      </Text>
      <FormControl id="name" mb="4"  isInvalid={errors.name}>
        <FormLabel>Name</FormLabel>
        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </FormControl>
      <FormControl id="email" mb="4" isInvalid={errors.email}>
        <FormLabel>Email</FormLabel>
        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </FormControl>
      <FormControl id="whatsapp" mb="4" isInvalid={errors.cellnr}>
        <FormLabel>Whatsapp</FormLabel>
        <Input type="number" value={cellnr} onChange={(e) => setCellnr(e.target.value)} />
      </FormControl>
      <FormControl id="message" mb="4" isInvalid={errors.message}>
        <FormLabel>Message</FormLabel>
        <Textarea value={message} onChange={(e) => setMessage(e.target.value)} />
      </FormControl>
      <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading} loadingText="Submitting" width="100%">
        {isLoading ? <Spinner size="sm" /> : "Submit"}
      </Button>
    </Box>
  );
};

export default Contact;
