import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Input, Select, useToast } from "@chakra-ui/react";
import axios from "axios";

const AddRegionStore = ({ isOpen, onClose, regions,retailers, refreshData }) => {
  const [storeCode, setStoreCode] = useState("");
  const [storeName, setStoreName] = useState("");
  const [retailer, setRetailer] = useState("");
  const [region, setRegion] = useState("");
  const toast = useToast();

  const handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/ins-store-region`, {
        site_code: storeCode,
        site_description:storeName,
        region,
        retailer
      });
      setStoreName("");
      setRegion("");
      toast({
        title: "Store added",
        description: "The new store has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      refreshData();
    } catch (error) {
      toast({
        title: "Failed to add store",
        description: "An error occurred while adding the new store.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Store</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <Input placeholder="Store Code" mb={4} value={storeCode} onChange={(e) => setStoreCode(e.target.value)} />
          <Input placeholder="Store Name" mb={4} value={storeName} onChange={(e) => setStoreName(e.target.value)} />
         
          <Select placeholder="Select Region" mb={4} value={region} onChange={(e) => setRegion(e.target.value)}>
            {regions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </Select>
          <Select placeholder="Select Retailer"  value={retailer} onChange={(e) => setRetailer(e.target.value)}>
            {retailers.map((retailer, index) => (
              <option key={index} value={retailer}>
                {retailer}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddRegionStore;
