import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";

const SupplierRetailerContext = createContext();

export const useSupplierRetailer = () => useContext(SupplierRetailerContext);

export const SupplierRetailerProvider = ({ children }) => {
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [retailers, setRetailers] = useState([
    { value: "PNP", text: "PnP" },
    { value: "CHK", text: "Checkers" },
    { value: "DIS", text: "Dischem" },
    { value: "CLI", text: "Clicks" },
    { value: "MAK", text: "Makro" },
  ]);
  const [user_supplier_access, setUser_supplier_access] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availableRetailers, setAvailableRetailers] = useState({});
  const [retailerLogos, setRetailerLogos] = useState({});

  const fetchUserAccess = async (userid) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/get--user-access`, { userid });
      const { user_supplier_access, user_defaults } = response.data.data;

      setUser_supplier_access(user_supplier_access);
      setSelectedSupplier(user_defaults.default_supplier);
      setSelectedRetailer(user_defaults.default_retailer);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSupplierAccess = async () => {
    try {
      const userid=localStorage.getItem("userid");
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-supplier-access`, { supplier: selectedSupplier,userid });
      setAvailableRetailers(response.data.data);
      const firstAvailableRetailer = Object.keys(response.data.data).find((key) => response.data.data[key]);
      if (firstAvailableRetailer) {
        setSelectedRetailer(firstAvailableRetailer);
      }
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const fetchLogos = async () => {
  //     const logoUrls = {};
  //     const retailerValues = ["PNP", "CHK", "DIS", "CLI", "MAK"];
  //     for (const value of retailerValues) {
  //       const logoRef = ref(storage, `retailers/${value.toLowerCase()}.jpg`);
  //       try {
  //         const url = await getDownloadURL(logoRef);
  //         logoUrls[value] = url;
  //       } catch (error) {
  //         console.error("Error fetching logo:", error);
  //         // Optionally use a default logo if there is an error fetching the retailer logo
  //       }
  //     }
  //     setRetailerLogos(logoUrls);
  //   };

  //   fetchLogos();
  // }, []);

  const resetContext = () => {
    setUser_supplier_access([]);
    setSelectedRetailer("");
    setSelectedSupplier("");
    setLoading(false);
    setAvailableRetailers({});
    // setRetailerLogos({});
  };

  useEffect(() => {
    setAvailableRetailers({});
    fetchSupplierAccess();
  }, [selectedSupplier]);

  return (
    <SupplierRetailerContext.Provider
      value={{
        selectedRetailer,
        setSelectedRetailer,
        selectedSupplier,
        setSelectedSupplier,
        retailers,
        user_supplier_access,
        loading,
        fetchUserAccess,
        resetContext,
        availableRetailers,
        fetchSupplierAccess
        // retailerLogos,
      }}
    >
      {children}
    </SupplierRetailerContext.Provider>
  );
};
