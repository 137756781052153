import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Flex, Stack, useTheme, Image } from "@chakra-ui/react";
import { FaShoppingCart, FaClipboardList, FaMedkit, FaRegChartBar, FaChartPie, FaInfoCircle, FaDatabase } from "react-icons/fa";
import { useSupplierRetailer } from "../wrappers/SupplierRetailerContext";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";

const RetailerNavigation = () => {
  const theme = useTheme();
  const retailerLogos = useState({ PNP: "/pnp.jpg", CHK: "/chk.jpg", DIS: "/dis.jpg", CLI: "/cli.jpg", MAK: "/mak.jpg" });
  const { selectedRetailer, setSelectedRetailer, retailers, availableRetailers } = useSupplierRetailer();
  const handleRetailerChange = (retailer) => {
    if (availableRetailers[retailer]) {
      setSelectedRetailer(retailer);
    }
  };

  const iconMap = {
    PNP: <FaShoppingCart />,
    CHK: <FaClipboardList />,
    DIS: <FaMedkit />,
    MAK: <FaRegChartBar />,
    CLI: <FaChartPie />,
    SUMMARY: <FaInfoCircle />,
    DATA: <FaDatabase />,
  };

  return (
    <Flex justify="space-between" mb={4} position="fixed" top="0" pt={2} bg="white" zIndex="1000" width="calc(100% - 170px)">
      <Flex align="center">
        <Stack direction="row" spacing={4}>
          <ButtonGroup spacing="4" mb="4">
            {retailers.map((btn) => (
              <Button
                key={btn.value}
                onClick={() => handleRetailerChange(btn.value)}
                bg={availableRetailers[btn.value] ? "white" : theme.colors.gray[200]}
                border={selectedRetailer === btn.value ? `2px solid ${theme.colors.primary1}` : "white"}
                boxShadow="lg"
                borderRadius="md"
                _hover={{
                  border: availableRetailers[btn.value] ? `1px solid ${theme.colors.primary2}` : "white",
                  cursor: availableRetailers[btn.value] ? "pointer" : "not-allowed",
                }}
                color={selectedRetailer === btn.value ? theme.colors.primary1 : theme.colors.textInactive}
                overflow="hidden"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                isDisabled={!availableRetailers[btn.value]}
                opacity={availableRetailers[btn.value] ? 1 : 0.6}
              >
                {<Image width="50px" src={retailerLogos[0][btn.value]} alt={btn.text} mr="12px" objectFit="cover" borderRadius="full" overflow="hidden" />}
                {btn.text}
              </Button>
            ))}
          </ButtonGroup>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default RetailerNavigation;
