import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Add this import
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDXFkFAqhTy_muxoUQ7p1DdFGHQrEm0RHY",
    authDomain: "spearbi-d6469.firebaseapp.com",
    projectId: "spearbi-d6469",
    storageBucket: "spearbi-d6469.appspot.com",
    messagingSenderId: "998472541157",
    appId: "1:998472541157:web:ab92161ef17e5db654c50c",
    measurementId: "G-PB0LHWY8HQ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app); // Initialize Firebase Authentication

export { app, analytics, storage, auth };
