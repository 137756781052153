import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Flex, IconButton, FormControl, FormLabel, Input, Button, Select, useToast, Stack } from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [password, setPassword] = useState("");
  const [default_supplier, setDefault_supplier] = useState("");
  const [default_retailer, setDefault_retailer] = useState("");
  const [user_type, setUser_type] = useState("Admin");

  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [availableRetailers, setAvailableRetailers] = useState(["PNP", "CHK", "DIS", "CLI", "MAK"]);
  const [availablePages, setAvailablePages] = useState([]);

  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedRetailerAccess, setSelectedRetailerAccess] = useState({});

  const [isRetailerAccessAvailable, setIsRetailerAccessAvailable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-suppliers-and-pages`);
        const { suppliers, pages } = response.data.data;
        if (!isRetailerAccessAvailable) {
          setAvailableSuppliers(suppliers.map((item) => item.suppliercode));
        }

        setAvailablePages(pages);
      } catch (error) {
        toast({
          title: "Failed to load data",
          description: "An error occurred while loading data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchData();
  }, [toast, isRetailerAccessAvailable]);

  useEffect(() => {
    if (isRetailerAccessAvailable) {
      setName(name.toUpperCase());
      setAvailableSuppliers([name]);
    }
  }, [name]);

  useEffect(() => {
    if (isRetailerAccessAvailable) {
      setName(name.toUpperCase());
      setAvailableSuppliers([name]);
    }
  }, [isRetailerAccessAvailable]);

  const handleNameChange = (e) => setName(e.target.value);
  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleUseremailChange = (e) => setUseremail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleDefaultRetailerChange = (e) => setDefault_retailer(e.target.value);
  const handleDefaultSupplierChange = (e) => setDefault_supplier(e.target.value);
  const handleUserTypeChange = (e) => {
    const userType = e.target.value;
    setUser_type(userType);
    setIsRetailerAccessAvailable(userType === "supplier" || userType === "SME");
    if (userType !== "supplier" && userType !== "SME") {
      setSelectedRetailerAccess({});
    }
  };

  const handleSupplierToggle = (supplier) => {
    setSelectedSuppliers((prev) => (prev.includes(supplier) ? prev.filter((item) => item !== supplier) : [...prev, supplier]));
  };

  const handlePageToggle = (page) => {
    setSelectedPages((prev) => (prev.includes(page) ? prev.filter((item) => item !== page) : [...prev, page]));
  };

  const handleRetailerToggle = (retailer) => {
    setSelectedRetailerAccess((prev) => ({
      ...prev,
      [retailer]: !prev[retailer],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      codename: name,
      username,
      useremail,
      password,
      default_retailer,
      default_supplier,
      user_type,
      suppliers: selectedSuppliers,
      pages: selectedPages,
      retailerAccess: isRetailerAccessAvailable ? selectedRetailerAccess : {},
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/ins-user`, payload);

      toast({
        title: response.data.title,
        description: response.data.message,
        status: response.data.status,
        duration: 3000,
        isClosable: true,
      });

      navigate("/users");
    } catch (error) {
      toast({
        title: "Failed to add user",
        description: "An error occurred while adding the user",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box overflowX="auto">
      <Flex justify="space-between" mb={4} align="center">
        <IconButton icon={<FaArrowLeft />} colorScheme="teal" onClick={() => navigate(-1)} aria-label="Go back" />
      </Flex>
      <Box width="500px" margin="0 auto" textAlign="center" padding="4" boxShadow="md" borderRadius="md" backgroundColor="white">
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>User Type</FormLabel>
            <Select value={user_type} onChange={handleUserTypeChange} placeholder="Select user type">
              <option value="admin">Admin</option>
              <option value="supplier">Supplier</option>
              <option value="SME">SME</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Supplier Code / User Full Names</FormLabel>
            <Input type="text" value={name} onChange={handleNameChange} placeholder="ie: ADRIATIC" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Username</FormLabel>
            <Input type="text" value={username} onChange={handleUsernameChange} placeholder="ie: spearHerbal" />
          </FormControl>

          <FormControl>
            <FormLabel>User Email</FormLabel>
            <Input type="text" value={useremail} onChange={handleUseremailChange} placeholder="ie: supplier@gmail.com" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input type="text" value={password} onChange={handlePasswordChange} autoComplete="new-password" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Default Retailer</FormLabel>
            <Select value={default_retailer} onChange={handleDefaultRetailerChange} placeholder="Select retailer">
              {availableRetailers.map((retailer) => (
                <option key={retailer} value={retailer}>
                  {retailer}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Default Supplier</FormLabel>
            <Select value={default_supplier} onChange={handleDefaultSupplierChange} placeholder="Select supplier">
              {availableSuppliers.map((supplier) => (
                <option key={supplier} value={supplier}>
                  {supplier}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Supplier Access</FormLabel>
            <Box>
              {availableSuppliers.map((supplier) => (
                <Button key={supplier} variant={selectedSuppliers.includes(supplier) ? "solid" : "outline"} colorScheme={selectedSuppliers.includes(supplier) ? "green" : "gray"} mr={2} mb={2} onClick={() => handleSupplierToggle(supplier)}>
                  {supplier}
                </Button>
              ))}
            </Box>
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Page Access</FormLabel>
            <Box>
              {availablePages.map((page) => (
                <Button key={page} variant={selectedPages.includes(page) ? "solid" : "outline"} colorScheme={selectedPages.includes(page) ? "green" : "gray"} mr={2} mb={2} onClick={() => handlePageToggle(page)}>
                  {page}
                </Button>
              ))}
            </Box>
          </FormControl>

          {isRetailerAccessAvailable && (
            <FormControl mt="2">
              <FormLabel>Retailer Access</FormLabel>
              <Box>
                {["PNP", "CHK", "DIS", "MAK", "CLI"].map((retailer) => (
                  <Button key={retailer} variant={selectedRetailerAccess[retailer.toLowerCase()] ? "solid" : "outline"} colorScheme={selectedRetailerAccess[retailer.toLowerCase()] ? "green" : "gray"} mr={2} mb={2} onClick={() => handleRetailerToggle(retailer.toLowerCase())}>
                    {retailer}
                  </Button>
                ))}
              </Box>
            </FormControl>
          )}

          <Box mt={4}>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AddUser;
