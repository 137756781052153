import React, { useState } from "react";
import { Box, Button, Flex, Stack, useTheme } from "@chakra-ui/react";

import Checkers from "./components/Checkers";
import Checkers_dc from "./components/Checkers_dc";
import Dischem_sales from "./components/Dischem_sales";
import Dischem_sdc from "./components/Dischem_sdc";
import Clicks from "./components/Clicks";
import SMEs from "./components/SMEs";

const Edi = () => {
  const [activeButton, setActiveButton] = useState("Checkers");
  const theme = useTheme();

  const renderComponent = () => {
    switch (activeButton) {
      case "Checkers":
        return <Checkers />;
      case "Checkers DC":
        return <Checkers_dc />;
      case "Dischem Sales":
        return <Dischem_sales />;
      case "Dischem SDC":
        return <Dischem_sdc />;
      case "clicksEdi":
        return <Clicks />;
        case "SMEs":
          return <SMEs />;
      default:
        return null;
    }
  };

  return (
    <Box mt={16}>
      <Flex justify="center" mb={4}>
        <Stack direction="row" spacing={4}>
          <Button onClick={() => setActiveButton("Checkers")} variant={activeButton === "Checkers" ? "solid" : "outline"} borderColor={activeButton === "Checkers" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Checkers
          </Button>
          <Button onClick={() => setActiveButton("Checkers DC")} variant={activeButton === "Checkers DC" ? "solid" : "outline"} borderColor={activeButton === "Checkers DC" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Checkers DC
          </Button>
          <Button onClick={() => setActiveButton("Dischem Sales")} variant={activeButton === "Dischem Sales" ? "solid" : "outline"} borderColor={activeButton === "Dischem Sales" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Dischem Sales
          </Button>
          <Button onClick={() => setActiveButton("Dischem SDC")} variant={activeButton === "Dischem SDC" ? "solid" : "outline"} borderColor={activeButton === "Dischem SDC" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Dischem SDC
          </Button>
          <Button onClick={() => setActiveButton("clicksEdi")} variant={activeButton === "clicksEdi" ? "solid" : "outline"} borderColor={activeButton === "clicksEdi" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Clicks EDI
          </Button>
          <Button onClick={() => setActiveButton("SMEs")} variant={activeButton === "SMEs" ? "solid" : "outline"} borderColor={activeButton === "SMEs" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
           SMEs EDI
          </Button>
        </Stack>
      </Flex>
      <Flex justifyContent="center">
        <Box width="1100px">{renderComponent()}</Box>
      </Flex>
    </Box>
  );
};

export default Edi;
