import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Select, Table, Thead, Tbody, Tr, Th, Td, FormControl, FormLabel, useTheme, Flex } from "@chakra-ui/react";

const SMEs = () => {
  const theme = useTheme();
  const [logins, setLogins] = useState([]);
  const [filteredLogins, setFilteredLogins] = useState([]);
  const [selectedRetailer, setSelectedRetailer] = useState("all");
  const [selectedSupplier, setSelectedSupplier] = useState("all");
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-edi-SMEs`, {}); // Replace with your API endpoint
        setLogins(response.data.data);
        setFilteredLogins(response.data.data);
        const supplierCodes = [...new Set(response.data.data.map((item) => item.suppliercode))];
        setSuppliers(supplierCodes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filtered = logins;

    if (selectedRetailer !== "all") {
      filtered = filtered.filter((login) => login.retailercode === selectedRetailer);
    }

    if (selectedSupplier !== "all") {
      filtered = filtered.filter((login) => login.suppliercode === selectedSupplier);
    }

    setFilteredLogins(filtered);
  }, [selectedRetailer, selectedSupplier, logins]);

  return (
    <Box p={4}>
      <Flex mb={4} justify="space-between" wrap="wrap">
        <FormControl mb={1} width="300px">
          <FormLabel>Filter by Retailer</FormLabel>
          <Select value={selectedRetailer} onChange={(e) => setSelectedRetailer(e.target.value)}>
            <option value="all">All</option>
            <option value="pnp">PnP</option>
            <option value="checkers">Checkers</option>
            <option value="dischem">Dischem</option>
            <option value="clicks">Clicks</option>
            <option value="makro">Makro</option>
          </Select>
        </FormControl>

        <FormControl mb={1} width="300px">
          <FormLabel>Filter by SME</FormLabel>
          <Select value={selectedSupplier} onChange={(e) => setSelectedSupplier(e.target.value)}>
            <option value="all">All</option>
            {suppliers.map((supplier) => (
              <option key={supplier} value={supplier}>
                {supplier}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>

      <Box height="630px" overflowY="scroll" border="1px" borderColor="gray.200" borderRadius="xl" boxShadow="lg">
        <Table variant="striped" colorScheme={theme.colors.primary1}>
          <Thead>
            <Tr>
              <Th>Supplier Code</Th>
              <Th>Retailer Code</Th>
              <Th>Username</Th>
              <Th>Password</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredLogins.map((login, index) => (
              <Tr key={index}>
                <Td>{login.suppliercode}</Td>
                <Td>{login.retailercode}</Td>
                <Td>{login.username}</Td>
                <Td>{login.userpass}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default SMEs;
