import React, { useState } from "react";
import { Box, Heading, useToast, Tooltip, Button, Input, FormControl, FormLabel, VStack, Step, StepIndicator, Stepper, StepStatus, StepTitle, StepDescription, StepIcon, StepSeparator, useSteps, HStack, Checkbox, useTheme, Image, AlertIcon, Alert } from "@chakra-ui/react";
import axios from "axios";

const steps = [
  { title: "Step 1", description: "SME Info" },
  { title: "Step 2", description: "Supplier Portals Info" },
];

const SignupPage = () => {
  const theme = useTheme();
  const toast = useToast();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [step1Data, setStep1Data] = useState({
    email: "",
    supplierName: "",
    vendorCode: "",
    firstName: "",
  });

  const [step2Data, setStep2Data] = useState({
    retailers: {
      pnp: { selected: false, username: "", password: "", value: "pnp" },
      dischem: { selected: false, username: "", password: "", value: "dis" },
      checkers: { selected: false, username: "", password: "", value: "chk" },
      makro: { selected: false, username: "", password: "", value: "mak" },
      clicks: { selected: false, username: "", password: "", value: "cli" },
    },
  });

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertStatus, setAlertStatus] = useState(null); // 'success' or 'error'

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (activeStep === 0) {
      setStep1Data((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      const [retailer, field] = name.split(".");
      setStep2Data((prevData) => ({
        ...prevData,
        retailers: {
          ...prevData.retailers,
          [retailer]: {
            ...prevData.retailers[retailer],
            [field]: value,
          },
        },
      }));
    }
  };

  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const handleEmailChange = (e) => {
    const { value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex

    setStep1Data((prevData) => ({
      ...prevData,
      email: value,
    }));

    if (!emailRegex.test(value)) {
      setEmailError(true);
      setEmailErrorMessage("Invalid email address supplied");
    } else {
      setEmailErrorMessage("");
      setEmailError(false);
    }
  };

  const [usernameError, setUsernameError] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const handleUsernameChange = (e) => {
    const { value } = e.target;

    const noSpacesOrSpecialChars = /^[a-zA-Z0-9]*$/;

    if (!noSpacesOrSpecialChars.test(value)) {
      setUsernameError(true);
      setUsernameErrorMessage("Username cannot contain spaces or special characters.");
    } else {
      setUsernameError(false);
      setUsernameErrorMessage("");
    }

    setStep1Data((prevData) => ({
      ...prevData,
      username: value,
    }));
  };

  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setStep1Data((prevData) => ({
      ...prevData,
      password: value,
    }));
    if (confirmPassword && value !== confirmPassword) {
      setPasswordError(true);
      setPasswordErrorMessage("Passwords do not match.");
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    if (value !== step1Data.password) {
      setPasswordError(true);
      setPasswordErrorMessage("Passwords do not match.");
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }
  };

  const [supplierNameError, setSupplierNameError] = useState(false);
  const [supplierNameErrorMessage, setSupplierNameErrorMessage] = useState("");
  const validateSupplierName = (value) => {
    const maxCharacters = 17;
    const noSpecialCharsRegex = /^[a-zA-Z0-9 ]*$/; // Only letters, numbers, and spaces
    const noConsecutiveSpaces = !/ {2,}/.test(value); // No consecutive spaces
    const noMoreThanThreeSpaces = value.split(" ").length - 1 <= 3; // Max 3 spaces

    if (value.length > maxCharacters) {
      setSupplierNameErrorMessage("Supplier name cannot exceed 15 characters.");
      return false;
    }

    if (!noConsecutiveSpaces) {
      setSupplierNameErrorMessage("Supplier name cannot contain consecutive spaces.");
      return false;
    }

    if (!noMoreThanThreeSpaces) {
      setSupplierNameErrorMessage("Supplier name cannot contain more than 3 non-consecutive spaces.");
      return false;
    }

    if (!noSpecialCharsRegex.test(value)) {
      setSupplierNameErrorMessage("Supplier name cannot contain special characters.");
      return false;
    }

    return true;
  };
  const handleSupplierCodeChange = (e) => {
    const { value } = e.target;

    setStep1Data((prevData) => ({
      ...prevData,
      supplierCode: value,
    }));

    if (!validateSupplierName(value)) {
      setSupplierNameError(true);
    } else {
      setSupplierNameError(false);
      setSupplierNameErrorMessage("");
    }
  };

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
  const validateFirstName = (value) => {
    const maxCharacters = 20;
    const noSpecialCharsRegex = /^[a-zA-Z0-9 ]*$/; // Only letters, numbers, and spaces
    if (value.length > maxCharacters) {
      setFirstNameErrorMessage("First name cannot exceed 20 characters.");
      return false;
    }
    if (!noSpecialCharsRegex.test(value)) {
      setFirstNameErrorMessage("First name cannot contain special characters.");
      return false;
    }
    return true;
  };
  const handleFirstNameChange = (e) => {
    const { value } = e.target;

    setStep1Data((prevData) => ({
      ...prevData,
      firstName: value,
    }));

    if (!validateFirstName(value)) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage("");
    }
  };

  const validateStep1 = async () => {
    let isEmailValid = step1Data.email && !emailError;
    let isUsernameValid = step1Data.username && !usernameError;
    let isSupplierNameValid = step1Data.supplierName && !supplierNameError;
    let isFirstNameValid = step1Data.firstName && !firstNameError;
    let isPasswordValid = step1Data.password && !passwordError && confirmPassword === step1Data.password;

    if (isEmailValid && isSupplierNameValid && isFirstNameValid && isPasswordValid && isUsernameValid) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/verify-credentials`, { useremail: step1Data.email, username: step1Data.username });
        if (response.data.status != "success") {
          toast({
            title: response.data.title,
            description: response.data.message,
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          if (response.data.type == "email") {
            setEmailError(true);
            setEmailErrorMessage(response.data.title);
            isEmailValid = false;
          } else if (response.data.type == "username") {
            setUsernameError(true);
            setUsernameErrorMessage(response.data.title);
            isUsernameValid = false;
          }
        }
        return isEmailValid && isSupplierNameValid && isFirstNameValid && isPasswordValid && isUsernameValid;
      } catch (error) {
        return false;
      }
    }
  };

  const handleRetailerToggle = (retailer) => {
    setStep2Data((prevData) => ({
      ...prevData,
      retailers: {
        ...prevData.retailers,
        [retailer]: {
          ...prevData.retailers[retailer],
          selected: !prevData.retailers[retailer].selected,
        },
      },
    }));
  };

  const handleSubmit = async () => {
    const postData = {
      fname: step1Data.firstName,
      username: step1Data.email,
      useremail: step1Data.email,
      userpass: step1Data.password,
      supplierCode: step1Data.supplierName,
      user_type: "SME",

      supplierPortals: Object.keys(step2Data.retailers)
        .filter((key) => step2Data.retailers[key].selected)
        .map((key) => ({
          retailer: step2Data.retailers[key].value,
          username: step2Data.retailers[key].username,
          password: step2Data.retailers[key].password,
          table: step2Data.retailers[key].tableName,
        })),

      retailers: Object.keys(step2Data.retailers).reduce((result, key) => {
        result[step2Data.retailers[key].value] = step2Data.retailers[key].selected;
        return result;
      }, {}),
    };
    console.log(postData);
    try {
      const outcome = await axios.post(`${process.env.REACT_APP_API_URL}/login/insert-sme-user`, postData);
      setAlertStatus(outcome.data.status);
      setAlertMessage(outcome.data.message);
    } catch (error) {
      setAlertStatus("error");
      setAlertMessage("An error occurred creating your account, IT has been notified and will contact you via email shortly.");
    }
  };

  return (
    <Box p={8}>
      {alertMessage && (
        <>
          <Alert status={alertStatus} mb={4}>
            <AlertIcon />
            {alertMessage}
          </Alert>

          <Box mt={4} p={4} borderWidth="1px" borderRadius="md">
            <Heading size="md" mb={4}>
              SME Information
            </Heading>

            <VStack align="start" spacing={4}>
              <Box>
                <strong>Email:</strong> {step1Data.email}
              </Box>
              <Box>
                <strong>Supplier Code:</strong> {step1Data.supplierCode}
              </Box>
              <Box>
                <strong>First Name:</strong> {step1Data.firstName}
              </Box>

              <Heading size="sm" mt={4} mb={2}>
                Supplier Portal Information
              </Heading>
              {Object.keys(step2Data.retailers).map((retailer) => {
                if (step2Data.retailers[retailer].selected) {
                  return (
                    <Box key={retailer} w="100%">
                      <strong>{retailer.toUpperCase()}:</strong>
                      <Box ml={4}>
                        <Box>
                          <strong>Username:</strong> {step2Data.retailers[retailer].username}
                        </Box>
                        <Box>
                          <strong>Password:</strong> {step2Data.retailers[retailer].password}
                        </Box>
                      </Box>
                    </Box>
                  );
                }
                return null;
              })}
            </VStack>
          </Box>
        </>
      )}

      {!alertMessage && (
        <>
          <Stepper index={activeStep} colorScheme="teal">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} incomplete={<StepIcon />} active={<StepIcon />} />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <VStack spacing={4} mt={8}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Tooltip label={emailErrorMessage} isOpen={emailError} placement="top" bg="red.500" color="white">
                  <Input type="email" value={step1Data.email} onChange={handleEmailChange} borderColor={emailError ? "red.500" : "gray.200"} autoComplete="off" />
                </Tooltip>
              </FormControl>

              <FormControl id="username">
                <FormLabel>Username</FormLabel>
                <Tooltip label={usernameErrorMessage} isOpen={usernameError} placement="top" bg="red.500" color="white">
                  <Input type="Username" value={step1Data.username} onChange={handleUsernameChange} borderColor={usernameError ? "red.500" : "gray.200"} autoComplete="off" />
                </Tooltip>
              </FormControl>

              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input type="password" name="password" value={step1Data.password} onChange={handlePasswordChange} borderColor={passwordError ? "red.500" : "gray.200"} autoComplete="new-password" />
              </FormControl>

              <FormControl id="confirmPassword">
                <FormLabel>Confirm Password</FormLabel>
                <Tooltip label={passwordErrorMessage} isOpen={passwordError} placement="top" bg="red.500" color="white">
                  <Input
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    borderColor={passwordError ? "red.500" : "gray.200"} // Optional: change border color if error
                  />
                </Tooltip>
              </FormControl>

              <FormControl id="supplierName">
                <FormLabel>Supplier Name</FormLabel>
                <Tooltip label={supplierNameErrorMessage} isOpen={supplierNameError} placement="top" bg="red.500" color="white">
                  <Input
                    value={step1Data.supplierName}
                    onChange={handleSupplierCodeChange}
                    borderColor={supplierNameError ? "red.500" : "gray.200"} // Optional: change border color if error
                  />
                </Tooltip>
              </FormControl>

              <FormControl id="firstName">
                <FormLabel>First Name</FormLabel>
                <Tooltip label={firstNameErrorMessage} isOpen={firstNameError} placement="top" bg="red.500" color="white">
                  <Input
                    type="text"
                    value={step1Data.firstName}
                    onChange={handleFirstNameChange}
                    borderColor={firstNameError ? "red.500" : "gray.200"} // Optional: change border color if error
                  />
                </Tooltip>
              </FormControl>

              <Button
                bg={theme.colors.primary1}
                _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }}
                color="white"
                onClick={async () => {
                  const isValid = await validateStep1(); // Await the validation result

                  if (isValid) {
                    setActiveStep(1);
                  } else {
                    if (!step1Data.email || emailError) {
                      setEmailError(true);
                    }
                    if (!step1Data.supplierName || supplierNameError) {
                      setSupplierNameError(true);
                    }
                    if (!step1Data.firstName || firstNameError) {
                      setFirstNameError(true);
                    }
                    if (!step1Data.password || passwordError || confirmPassword !== step1Data.password) {
                      setPasswordError(true);
                      if (confirmPassword !== step1Data.password) {
                        setPasswordErrorMessage("Passwords do not match.");
                      }
                    }
                  }
                }}
              >
                Next
              </Button>
            </VStack>
          )}

          {activeStep === 1 && (
            <VStack spacing={4} mt={8}>
              {Object.keys(step2Data.retailers).map((retailer) => (
                <Box key={retailer} w="100%">
                  <HStack spacing={4}>
                    <Checkbox isChecked={step2Data.retailers[retailer].selected} onChange={() => handleRetailerToggle(retailer)}>
                      <HStack>
                        <Image width="100px" mr="12px" objectFit="contain" borderRadius="full" overflow="hidden" minHeight="30px" src={`/${step2Data.retailers[retailer].value}.jpg`} alt={`$retailer`} />
                      </HStack>
                    </Checkbox>
                  </HStack>
                  {step2Data.retailers[retailer].selected && (
                    <VStack mt={2} spacing={4} align="start">
                      <FormControl>
                        <FormLabel>{`${retailer.toUpperCase()} Username`}</FormLabel>
                        <Input name={`${retailer}.username`} value={step2Data.retailers[retailer].username} onChange={handleInputChange} />
                      </FormControl>
                      <FormControl>
                        <FormLabel>{`${retailer.toUpperCase()} Password`}</FormLabel>
                        <Input type="text" name={`${retailer}.password`} value={step2Data.retailers[retailer].password} onChange={handleInputChange} />
                      </FormControl>
                    </VStack>
                  )}
                </Box>
              ))}
              <HStack spacing={4}>
                <Button bg={theme.colors.primary1} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="white" onClick={() => setActiveStep(0)}>
                  Back
                </Button>
                <Button bg={theme.colors.primary1} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="white" onClick={handleSubmit}>
                  Submit
                </Button>
              </HStack>
            </VStack>
          )}
        </>
      )}
    </Box>
  );
};

export default SignupPage;
