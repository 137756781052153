import React from 'react';

const SupplierPortal = () => {
  return (
    <div>
      <h2>Supplier Portal</h2>
      <p>Details for the Supplier Portal go here.</p>
    </div>
  );
};

export default SupplierPortal;
