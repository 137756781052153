import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import Account from "./components/Account";
import SupplierPortal from "./components/SupplierPortal";
import Subscription from "./components/Subscription";
import { useSupplierRetailer } from "../../wrappers/SupplierRetailerContext";

const MyProfile = () => {
  const { selectedSupplier } = useSupplierRetailer();

  return (
    <Box p={4} mt={8}>
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Subscription</Tab>
          <Tab>Profile</Tab>
          <Tab>Supplier Portal Crednetials</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Subscription supplier={selectedSupplier} />
          </TabPanel>
          <TabPanel>
            <Account supplier={selectedSupplier} />
          </TabPanel>
          <TabPanel>
            <SupplierPortal supplier={selectedSupplier} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default MyProfile;
