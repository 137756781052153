import React, { useState } from "react";
import { Box, Button, Flex, Stack, useTheme } from "@chakra-ui/react";
import RegionsTable from "./components/RegionsTable";
import ExcludedTable from "./components/ExcludedTable";
import DcTable from "./components/DcTable";

const Stores = () => {
  const [activeButton, setActiveButton] = useState("storeRegions");
  const theme = useTheme();

  const renderTable = () => {
    switch (activeButton) {
      case "storeRegions":
        return <RegionsTable />;
      case "excludedStores":
        return <ExcludedTable />;
      case "dcStores":
        return <DcTable />;
      default:
        return null;
    }
  };

  return (
    <Box mt={16}>
      <Flex justify="center" mb={4}>
        <Stack direction="row" spacing={4}>
          <Button onClick={() => setActiveButton("storeRegions")} variant={activeButton === "storeRegions" ? "solid" : "outline"} borderColor={activeButton === "storeRegions" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Store Regions
          </Button>
          <Button onClick={() => setActiveButton("excludedStores")} variant={activeButton === "excludedStores" ? "solid" : "outline"} borderColor={activeButton === "excludedStores" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            Excluded Stores
          </Button>
          <Button onClick={() => setActiveButton("dcStores")} variant={activeButton === "dcStores" ? "solid" : "outline"} borderColor={activeButton === "dcStores" ? theme.colors.primary1 : "transparent"} borderWidth="2px">
            DC Stores
          </Button>
        </Stack>
      </Flex>
      <Flex justifyContent="left" ml={24}>
        <Box width="1100px">{renderTable()}</Box>
      </Flex>
    </Box>
  );
};

export default Stores;
