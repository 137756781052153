import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, useTheme, Heading, Box,Spinner } from "@chakra-ui/react";
import axios from "axios";

const TableWeekly = ({ selectedRetailer, selectedSupplier }) => {
  const [TheadData, setTheadData] = useState([]);
  const [TbodyData, setTbodyData] = useState([]);

  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchTableWeekly = async () => {
        setLoading(true);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_strikeRate/get-table-weekly`, {
          retailer: selectedRetailer,
          supplier: selectedSupplier,
        });
        setTheadData(res.data.data.tableData.thead);
        setTbodyData(res.data.data.tableData.tbody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching weekly table data:", error);
        setLoading(false);
      }
    };

    fetchTableWeekly();
  }, [selectedRetailer, selectedSupplier]);

  function formatDateString(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);
  
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
  
    return `${day} ${month} '${year}`;
  }

  if (loading) return <Spinner />;
  
  return (
    <Box flex="1" maxWidth="27%" pr={1} overflow="hidden">
      <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
        Weekly
      </Heading>
      <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
        <Thead>
        {TheadData.map((row, index) => (
            <Tr key={index}>
              <Th fontSize="xs" whiteSpace="nowrap">{row.th1}</Th>
              <Th fontSize="xs" whiteSpace="wrap">{row.th2}</Th>
              <Th fontSize="xs" whiteSpace="wrap">{row.th3}</Th>
              <Th fontSize="xs" whiteSpace="wrap">{row.th4}</Th>
              <Th fontSize="xs" whiteSpace="nowrap">{row.th5}</Th>
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {TbodyData.map((row, index) => (
            <Tr key={index}>
              <Td fontSize="xs" whiteSpace="nowrap">{formatDateString(row.week_ending_date)}</Td>
              <Td fontSize="xs" whiteSpace="nowrap">{row.po_order_qty}</Td>
              <Td fontSize="xs" whiteSpace="nowrap">{row.po_receipt_qty}</Td>
              <Td fontSize="xs" whiteSpace="nowrap">{row.strike_rate}</Td>
              <Td fontSize="xs" whiteSpace="nowrap">{row.value}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TableWeekly;
