import React, { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Stack,
  Td, // Import Td here
} from "@chakra-ui/react";
import FocusLock from "react-focus-lock";

const EditablePopover = ({ label, value, onSubmit, fieldName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newValue, setNewValue] = useState(value);

  const handleSubmit = () => {
    onSubmit(fieldName, newValue);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Td px={1} py={1} onDoubleClick={onOpen}>
          {value}
        </Td>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Edit {label}</FormLabel>
              <Input value={newValue} onChange={(e) => setNewValue(e.target.value)} />
            </FormControl>
            <Button colorScheme="teal" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default EditablePopover;
