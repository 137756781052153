import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Spinner, useTheme, Box, Spacer, Heading } from "@chakra-ui/react";
import axios from "axios";
import Exports from "./Exports";

const Table1 = ({ supplier, retailer }) => {
  const [thead, setThead] = useState({ th0: [], th1: [] });
  const [tbody, setTbody] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const buildTable = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_ranging/html-rangingReport`, {
          supplier,
          retailer,
          userid: localStorage.getItem("userid"),
        });
        const { status, data, message } = res.data;
        if (status === "success") {
          setThead(data.head);
          setTbody(data.body);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
        setLoading(false);
      }
    };

    buildTable();
  }, [supplier, retailer]);

  if (loading) return <Spinner />;

  return (
    <Box>
      <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
        Ranging Report
      </Heading>

      <Box mr={4} mt={16}>
        <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
          <Thead bg="gray.200" color="white"  position="sticky" top={0} zIndex={1}>
            <Tr>
              {thead.th0.map((th, index) => (
               <Th 
               key={`th0-${index}`} 
               width={index === 0 ? "100px" : index === 1 ? "220px" : "auto"} 
               borderRight="1px solid white"
               position={index < 2 ? "sticky" : "static"}
               left={index === 0 ? 0 : index === 1 ? "100px" : "auto"}
               zIndex={2}
               bg="gray.200"
             >
                  {th}
                </Th>
              ))}
            </Tr>
            <Tr>
              {thead.th1.map((th, index) => (
                <Th 
                key={`th1-${index}`} 
                width={index === 0 ? "100px" : index === 1 ? "220px" : "auto"} 
                borderRight="1px solid white"
                position={index < 2 ? "sticky" : "static"}
                left={index === 0 ? 0 : index === 1 ? "100px" : "auto"}
                zIndex={2}
                bg="gray.200"
              >
                  {th}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {tbody.map((row, rowIndex) => (
              <Tr key={`row-${rowIndex}`}>
                {row.map((cell, cellIndex) => (
                  <Td
                  key={`cell-${rowIndex}-${cellIndex}`}
                  sx={{
                    backgroundColor: `${cell.color} !important`,
                    whiteSpace: "nowrap",
                    minWidth: cellIndex === 0 ? "100px" : cellIndex === 1 ? "200px" : "200px",
                  }}
                  width={cellIndex === 0 ? "100px" : cellIndex === 1 ? "200px" : "250px"}
                  borderRight="1px solid white"
                  position={cellIndex < 2 ? "sticky" : "static"}
                  left={cellIndex === 0 ? 0 : cellIndex === 1 ? "100px" : "auto"}
                  zIndex={1}
                >
                    {cell.value}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Table1;
