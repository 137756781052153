import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, FormControl, FormLabel, Select, useToast, Flex, IconButton } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";


const EditRetailerAccess = () => {

  const { userid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [default_retailer, setDefault_retailer] = useState("");
  const [availableRetailers] = useState(["PNP", "CHK", "DIS", "MAK", "CLI"]);
  const [selectedRetailerAccess, setSelectedRetailerAccess] = useState({});
  const [isRetailerAccessAvailable, setIsRetailerAccessAvailable] = useState(false);
  const [user, setUser] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-user-retailerAccess`, { userid });
        const { retailer_access, user } = response.data.data;
        console.log(retailer_access);
        setDefault_retailer(user.default_retailer);
        setUser(user);
        setSelectedRetailerAccess(retailer_access.length > 0 ? retailer_access[0] : {});
        setIsRetailerAccessAvailable(retailer_access.length > 0);
      } catch (error) {
        toast({
          title: "Failed to load retailer access data",
          description: "An error occurred while loading data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, [userid, toast]);

  const handleDefaultRetailerChange = (e) => setDefault_retailer(e.target.value);

  const handleRetailerToggle = (retailer) => {
    setSelectedRetailerAccess((prev) => ({
      ...prev,
      [retailer]: !prev[retailer],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-user-retailerAccess`, {
        userid,
        default_retailer,
        retailerAccess: selectedRetailerAccess,
      });
      toast({
        title: response.data.title,
        description: response.data.description,
        status: response.data.status,
        duration: 3000,
        isClosable: true,
      });
      navigate("/users");
    } catch (error) {
      toast({
        title: "Failed to update retailer access",
        description: "An error occurred while updating retailer access",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box width="500px" margin="50px auto" textAlign="center" padding="4" boxShadow="md" borderRadius="md" backgroundColor="white">
      <Flex justify="space-between" mb={4} align="center">
        <IconButton icon={<FaArrowLeft />} colorScheme="teal" onClick={() => navigate("/users")} aria-label="Go back" />
      </Flex>
      <form onSubmit={handleSubmit}>
        <FormLabel textAlign="center" width="100%">
          {user.username}
        </FormLabel>
        <FormControl isRequired>
          <FormLabel>Default Retailer</FormLabel>
          <Select value={default_retailer} onChange={handleDefaultRetailerChange} placeholder="Select retailer">
            {availableRetailers.map((retailer) => (
              <option key={retailer} value={retailer}>
                {retailer}
              </option>
            ))}
          </Select>
        </FormControl>

        {isRetailerAccessAvailable && (
          <FormControl mt="2">
            <FormLabel>Retailer Access</FormLabel>
            <Box>
              {availableRetailers.map((retailer) => (
                <Button key={retailer} variant={selectedRetailerAccess[retailer.toLowerCase()] ? "solid" : "outline"} colorScheme={selectedRetailerAccess[retailer.toLowerCase()] ? "green" : "gray"} mr={2} mb={2} onClick={() => handleRetailerToggle(retailer.toLowerCase())}>
                  {retailer}
                </Button>
              ))}
            </Box>
          </FormControl>
        )}

        <Box mt={4}>
          <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
            Submit
          </Button>
          <Button onClick={() => navigate("/users")} colorScheme="gray" ml={4}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditRetailerAccess;
