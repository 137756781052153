import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const PageAccessContext = createContext();

export const usePageAccess = () => useContext(PageAccessContext);

export const PageAccessProvider = ({ children }) => {
  const [user_page_access, setUser_page_access] = useState([]);
  const [loading, setLoading] = useState(true);

  const [restrictedyn, setRestrictedyn] = useState(false);
  const [subscriptionyn, setSubscriptionyn] = useState(false);
  const [trialyn, setTrialyn] = useState(false);
  const [paymentReminder, setPaymentReminder] = useState("");

  const fetchPageAccess = async (userid) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/get--user-access`, { userid });
      const { user_page_access, access } = response.data.data;
      console.log({ user_page_access, access });
      setUser_page_access(user_page_access);
      setRestrictedyn(access.restrictedyn);
      setSubscriptionyn(access.subscriptionyn);
      setTrialyn(access.trialyn);
      setPaymentReminder(access.paymentReminder);
    } catch (error) {
      console.error("Error fetching page access", error);
    } finally {
      setLoading(false);
    }
  };

  const resetContext = () => {
    setUser_page_access([]);
    setLoading(false);
  };

  return <PageAccessContext.Provider value={{ 
    user_page_access,
     fetchPageAccess, 
     loading,restrictedyn,subscriptionyn,trialyn,paymentReminder,
      resetContext,
     }}>{children}</PageAccessContext.Provider>;
};
