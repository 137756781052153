import React, { useEffect, useState } from "react";
import { Image, Box } from "@chakra-ui/react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";
import { useSupplierRetailer } from "../wrappers/SupplierRetailerContext";

const SupplierLogo = () => {
  const { selectedSupplier } = useSupplierRetailer();
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      if (!selectedSupplier) return;
      const logoRef = ref(storage, `logos/${selectedSupplier}.jpg`);
      try {
        const url = await getDownloadURL(logoRef);
        setLogoUrl(url);
      } catch (error) {
        console.error("Error fetching logo:", error);
        const defaultLogoRef = ref(storage, `logos/blank.jpg`);
        try {
          const defaultUrl = await getDownloadURL(defaultLogoRef);
          setLogoUrl(defaultUrl);
        } catch (defaultError) {
          console.error("Error fetching default logo:", defaultError);
        }
      }
    };

    fetchLogo();
  }, [selectedSupplier]);

  return (
    <Box position="fixed" top="0px" right="10px" zIndex={3000} boxShadow="lg" borderRadius="md">
      {logoUrl && <Image boxSize="60px" objectFit="contain" src={logoUrl} alt={`${selectedSupplier} logo`} />}
    </Box>
  );
};

export default SupplierLogo;
