import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Select, useToast, useTheme, Heading, Box, Spinner } from "@chakra-ui/react";
import axios from "axios";

const TableReason = ({ selectedRetailer, selectedSupplier, ddStrikeRate }) => {
  const [TheadData, setTheadData] = useState([]);
  const [TbodyData, setTbodyData] = useState([]);

  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const theme = useTheme();

  useEffect(() => {
    const fetchTableReason = async () => {
      setLoading(true);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_strikeRate/get-table-reason`, {
          retailer: selectedRetailer,
          supplier: selectedSupplier,
        });
        setTheadData(res.data.data.tableData.thead);
        setTbodyData(res.data.data.tableData.tbody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reason table data:", error);
        setLoading(false);
      }
    };

    fetchTableReason();
  }, [selectedRetailer, selectedSupplier]);

  if (loading) return <Spinner />;
  return (
    <Box flex="1" maxWidth="64%" pr={1}>
      <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
        Reason
      </Heading>
      <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
        <Thead>
          {TheadData.map((row, index) => (
            <Tr key={index}>
              <Th fontSize="xs" whiteSpace="nowrap">
                {row.th1}
              </Th>
              <Th fontSize="xs" whiteSpace="wrap">
                {row.th2}
              </Th>
              <Th fontSize="xs" whiteSpace="wrap">
                {row.th3}
              </Th>
              <Th fontSize="xs" whiteSpace="wrap">
                {row.th4}
              </Th>
              <Th fontSize="xs" whiteSpace="nowrap">
                {row.th5}
              </Th>
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {TbodyData.map((row, index) => (
            <Tr key={index}>
              <Td fontSize="xs" whiteSpace="nowrap">
                {row.reason}
              </Td>
              <Td fontSize="xs" whiteSpace="nowrap">
                {row.order_qty}
              </Td>
              <Td fontSize="xs" whiteSpace="nowrap">
                {row.receipt_qty}
              </Td>
              <Td fontSize="xs" whiteSpace="nowrap">
                {row.strike_rate}
              </Td>
              <Td fontSize="xs" whiteSpace="nowrap">
                {row.value}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TableReason;
