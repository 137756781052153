import React, { useState, useEffect } from "react";
import { Button, Box, VStack, useTheme, useToast } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons"; // You can replace this with an appropriate CSV/Excel icon

const Exports = ({ supplier, retailer }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const toast = useToast();

  const fetchCsv = async (vars, url, name) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(vars),
    };

    try {
      setIsLoading(true);
      const response = await fetch(url, requestOptions);
      setIsLoading(false);

      const contentType = response.headers.get("content-type");
      console.log("contentType: ", contentType);
      if (contentType && (contentType.includes("text/csv") || contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${name}_${vars.supplier}_${vars.retailer}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast({
          title: "Success",
          description: `${name} file downloaded successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log("error", response);
        toast({
          title: "Error",
          description: "An error occurred while downloading the file.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setIsLoading(false);
      toast({
        title: "Technical Error",
        description: "A technical error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRangingClick = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_ranging/csv-rangingReport`;
      const name = "Ranging Report";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  useEffect(() => {
    if (showButtons) {
      const timer = setTimeout(() => {
        setShowButtons(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showButtons]);

  return (
    <Box onMouseEnter={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)} 
    bg="white" p={2} borderRadius={12} mt={40}>
      <Button leftIcon={<DownloadIcon />} bg={theme.colors.primary1} 
      _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} 
      color="white" variant="solid" isLoading={isLoading}>
        Export
      </Button>
      {showButtons && (
        <VStack mt={1}>
          <Button w="100%" bg={theme.colors.primary1}
           _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }}
            color="white" variant="outline" onClick={handleRangingClick}>
            Ranging report
          </Button>
        </VStack>
      )}
    </Box>
  );
};

export default Exports;
