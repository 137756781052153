import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Spinner, useTheme, Box, Input, IconButton, InputGroup, InputRightElement, Tooltip, Spacer, Button, Heading } from "@chakra-ui/react";
import { SearchIcon, CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import axios from "axios";
import Exports from "./Exports";

const Table1 = ({ supplier, retailer }) => {
  const [thead, setThead] = useState([]);
  const [tbody, setTbody] = useState([]);
  const [filteredTbody, setFilteredTbody] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = useTheme({ supplier, retailer });

  useEffect(() => {
    setLoading(true);
    const buildTable = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_margin/table1`, {
          supplier,
          retailer,
          userid: localStorage.getItem("userid"),
        });
        setThead(res.data.data.tableData.thead);
        setTbody(res.data.data.tableData.tbody);
        setFilteredTbody(res.data.data.tableData.tbody);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
        setLoading(false);
      }
    };

    buildTable();
  }, [supplier, retailer]);

  const handleSearch = () => {
    const lowercasedFilter = searchText.toLowerCase();
    const filteredData = tbody.filter((item) => {
      return item.some((col, index) => {
        if (index === 1 || index === 2 || index === 3) {
          const colData = Array.isArray(col) ? col[0] : col;
          return colData.toString().toLowerCase().includes(lowercasedFilter);
        }
        return false;
      });
    });
    setFilteredTbody(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredTbody(tbody);
  };

  useEffect(() => {
    if (searchText) {
      setShowTooltip(true);
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setShowTooltip(false);
    }
  }, [searchText]);

  const getAttributes = (attributes) => {
    const attrObj = {};
    if (Array.isArray(attributes)) {
      attributes.forEach((attr) => {
        if (attr.key === "class") {
          Object.assign(attrObj, mapClassNameToChakraProps(attr.value));
        } else if (attr.key === "colspan") {
          attrObj.colSpan = attr.value;
        } else if (attr.key === "rowspan") {
          attrObj.rowSpan = attr.value;
        } else {
          attrObj[attr.key] = attr.value;
        }
      });
    } else {
      if (attributes.key === "class") {
        Object.assign(attrObj, mapClassNameToChakraProps(attributes.value));
      } else if (attributes.key === "colspan") {
        attrObj.colSpan = attributes.value;
      } else if (attributes.key === "rowspan") {
        attrObj.rowSpan = attributes.value;
      } else {
        attrObj[attributes.key] = attributes.value;
      }
    }

    return attrObj;
  };

  const mapClassNameToChakraProps = (className) => {
    switch (className) {
      case "th1_c1":
        return {
          bg: `rgba(255,255,255,1) !important`,
        };
      case "th1_c2":
        return {
          bg: `rgba(255,255,255,1) !important`,
        };
      case "th1_c3":
        return {
          bg: `rgba(255,255,255,1) !important`,
        };

      case "th2_aCode":
        return {
          bgGradient: `linear(to-b, #fff,rgba(88,182,182,0.3) )`,
        };
      case "th2_aName":
        return {
          bgGradient: `linear(to-b, #fff,rgba(88,182,182,0.3) )`,
        };
      case "th2_aCost":
        return {
          bgGradient: `linear(to-b, #fff,rgba(45, 147, 225, 0.3) )`,
        };
      case "th2_RSP":
        return {
          bgGradient: `linear(to-b, #fff,rgba(88,182,182,0.3) )`,
        };
      case "th2_margin":
        return {
          bgGradient: `linear(to-b, #fff,rgba(88,182,182,0.3) )`,
        };

      case "sticky_c1":
        return {
          position: "sticky",
          left: "0",
          zIndex: "1",
          bgGradient: "linear(to-r, #fff, rgba(88,182,182,0.2))",
        };
      case "sticky_c2":
        return {
          position: "sticky",
          left: "60px",
          zIndex: "1",
          bgGradient: "linear(to-r, #fff, rgba(88,182,182,0.2))",

        };
      case "sticky_c3":
        return {
          bgGradient: "linear(to-r, #fff, rgba(45, 147, 225, 0.1))",
        };

      case "th1_group1":
        return {
          textAlign: "center",
          bgGradient: `linear(to-b, #fff,rgba(219, 219, 219, 0.3),rgba(219, 219, 219, 0.6))`,
        };
      case "td_RSP1":
        return {
          bgGradient: `linear(to-r, #fff,rgba(227, 227, 227,0.6), rgba(219, 219, 219,0.6))`,
        };
      case "td_margin1":
        return {
          bgGradient: `linear(to-r, #fff,rgba(227, 227, 227,0.6), rgba(219, 219, 219,0.6))`,
        };

      case "th1_group2":
        return {
          textAlign: "center",
          bgGradient: `linear(to-b, #fff,rgba(238, 242, 243, 0.4))`,
        };
      case "td_RSP2":
        return {
          bgGradient: `linear(to-r, #fff,#fff, rgba(227, 227, 227,0.4))`,
        };
      case "td_margin2":
        return {
          bgGradient: `linear(to-r, #fff,#fff, rgba(227, 227, 227,0.4))`,
        };

      case "th1_groupTotals":
        return {
          textAlign: "center",
          bgGradient: `linear(to-b, #fff,rgba(45, 225, 225, 0.4))`,
        };
      case "td_RSPTotal":
        return {
          fontWeight: `bold !important`,
          bgGradient: `linear(to-r, #fff,rgba(45, 225, 225, 0.2))`,

        };
      case "td_marginTotal":
        return {
          fontWeight: `bold !important`,
          bgGradient: `linear(to-r, #fff,rgba(15, 255, 255, 0.3))`,
        };

      case "td_RSPGrandTotal":
        return {
          fontWeight: `bold !important`,
          bg: `white !important`,
        };
      case "td_marginGrandTotal":
        return {
          fontWeight: `bold !important`,
          bgGradient: `linear(to-r, #fff,rgba(255, 107, 15, 0.45))`,
        };
    
        default:
        return {};
    }
  };

  if (loading) return <Spinner />;

  return (
    <Box>
      <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
        Margin Report
      </Heading>
      <Box mb={4} display="flex" justifyContent="flex-start" alignItems="center">
        <Box position="absolute" zIndex={2000} mr={4}>
           <Exports supplier={supplier} retailer={retailer} /> 
        </Box>
        <InputGroup size="md" width="400px" ml={32}>
          <Tooltip label="Did you know you can press Enter to Search and Esc to clear search" isOpen={showTooltip} placement="right">
            <Input
              pr="4.5rem"
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                console.log(e.key);
                if (e.key === "Enter") {
                  handleSearch();
                } else if (e.key === "Escape") {
                  handleClearSearch();
                }
              }}
            />
          </Tooltip>
          <InputRightElement width="4.5rem">
            {searchText && <IconButton h="1.75rem" size="sm" onClick={handleClearSearch} icon={<CloseIcon />} mr={2} />}
            <IconButton h="1.75rem" size="sm" onClick={handleSearch} icon={<SearchIcon />} />
          </InputRightElement>
        </InputGroup>
        <Spacer />
      </Box>
      <Box maxHeight="700px" overflowY="auto" maxWidth="97vw">
        <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
          <Thead bg="gray.200" color="white">
            {thead.map((row, rowIndex) => (
              <Tr key={`thead-row-${rowIndex}`}>
                {row.map((col, colIndex) => {
                  const colData = Array.isArray(col) ? col[0] : col;
                  const attributes = Array.isArray(col) && col[1] ? getAttributes(col[1]) : {};

                  return (
                    <Th key={`thead-cell-${rowIndex}-${colIndex}`} {...attributes} p={1} fontSize="xs" whiteSpace="nowrap" borderRight="1px" borderColor="white">
                      {colData}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {filteredTbody.map((row, rowIndex) => (
              <Tr key={`tbody-row-${rowIndex}`}>
                {row.map((col, colIndex) => {
                  const colData = Array.isArray(col) ? col[0] : col;
                  const attributes = Array.isArray(col) && col[1] ? getAttributes(col[1]) : {};

                  return (
                    <Td key={`tbody-cell-${rowIndex}-${colIndex}`} {...attributes} p={1} fontSize="xs" whiteSpace="nowrap" borderRight="1px" borderColor="gray.200">
                      {colData}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Table1;
