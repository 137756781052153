import React from "react";
import { Box } from "@chakra-ui/react";

const TrialBanner = ({ trialyn }) => {
  if (!trialyn) {
    return null;
  }

  return (
    <Box
      bg="transparent"
      color="black"
      p={2}
      textAlign="center"
      fontWeight="bold"
      borderRadius="md"
      boxShadow="md"
      position="fixed"
      top="10px"
      width="150px"
      right="100px"
      zIndex="2000"
    >
      Trial Period
    </Box>
  );
};

export default TrialBanner;
