import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Input, useToast } from "@chakra-ui/react";
import axios from "axios";

const AddExcludedStore = ({ isOpen, onClose, refreshData }) => {
  const [retailer, setRetailer] = useState("");
  const [siteCode, setSiteCode] = useState("");
  const toast = useToast();

  const handleSave = async () => {
    try {
        let formattedSiteCode = siteCode;
        if (!formattedSiteCode.startsWith('%')) {
          formattedSiteCode = `%${formattedSiteCode}`;
        }
        if (!formattedSiteCode.endsWith('%')) {
          formattedSiteCode = `${formattedSiteCode}%`;
        }
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/ins-excluded-store`, {
        retailer,
        site_code: formattedSiteCode,
      });
      setSiteCode('');
      setRetailer('');
      toast({
        title: "Store added",
        description: "The new excluded store has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      refreshData();
    } catch (error) {
      toast({
        title: "Failed to add store",
        description: "An error occurred while adding the new store.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Excluded Store</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input placeholder="Retailer" mb={4} value={retailer} onChange={(e) => setRetailer(e.target.value)} />
          <Input placeholder="Site Code" mb={4} value={siteCode} onChange={(e) => setSiteCode(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddExcludedStore;
