import React, { useState, useEffect } from "react";
import { Box, FormControl, FormLabel, Select, Input, Button, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text } from "@chakra-ui/react";
import { useSupplierRetailer } from "../../../wrappers/SupplierRetailerContext";

const Imports = () => {
  const { selectedRetailer, selectedSupplier } = useSupplierRetailer();
  const [dataType, setDataType] = useState("");
  const [importOption, setImportOption] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [imports, setImports] = useState({
    PNP: [
      { value: "sales", text: "Sales" },
      { value: "sdc", text: "SDC" },
      { value: "articles", text: "Articles" },
      { value: "stores", text: "Stores" },
      { value: "strikerate", text: "Strike Rate" },
    ],
    CHK: [
      { value: "sales", text: "Sales" },
      { value: "sdc", text: "SDC" },
      { value: "dc", text: "DC" },
      { value: "articles", text: "Articles" },
      { value: "stores", text: "Stores" },
    ],
    DIS: [
      { value: "sales", text: "Sales" },
      { value: "sdc", text: "SDC" },//sdc units
      { value: "ranging", text: "Ranging" },//site_article_status
      { value: "articles", text: "Articles" },
      { value: "stores", text: "Stores" },
    ],
    CLI: [
      { value: "Sales", text: "Sales" },
      { value: "sdc", text: "SDC" },
      { value: "dc", text: "DC" },
      { value: "ranging", text: "Ranging" },
      { value: "articles", text: "Articles" },
      { value: "Stores", text: "Stores" },
    ],
    MAK: [
      { value: "sales", text: "Sales" },
      { value: "sdc", text: "SDC" },
      { value: "articles", text: "Articles" },
      { value: "stores", text: "Stores" },
    ],
  });
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (selectedRetailer) {
      setOptions(imports[selectedRetailer] || []);
    }
  }, [selectedRetailer, imports]);

  const handleSubmit = async () => {
    onOpen();
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0]?.name || "");
  };

  const confirmSubmit = async () => {
    onClose();
    setIsLoading(true);

    if (!file) {
      toast({
        title: "Error",
        description: "Please select a file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    let supplier = selectedSupplier; // Replace with your actual supplier value
    const vars = {
      supplier: supplier.toLowerCase(),
      retailer: selectedRetailer.toLowerCase(),
      option:importOption.toLowerCase()
    };

    const formData = new FormData();
    formData.append("xlsxFile", file);
    Object.keys(vars).forEach((key) => {
      formData.append(key, vars[key]);
    });
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/data/uploads/`, {
        method: "POST",
        body: formData,
      });
      const status = response.status;
      const data = await response.json();
      console.log({ data });
      if (status === 201) {
        toast({
          title: "Success",
          description: data.message,
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: data.message,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast({
        title: "Error",
        description: `Exception occurred during file upload: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <Box maxW="500px" mx="auto" mt={8} p={4} borderWidth="1px" borderRadius="lg">
      <FormControl mb={4}>
        <FormLabel>Selected Retailer: {selectedRetailer}</FormLabel>
      </FormControl>
      <FormControl mb={4} isRequired>
        <FormLabel>Data Type</FormLabel>
        <Select placeholder="Select data type" value={importOption} onChange={(e) => setImportOption(e.target.value)}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl mb={4} isRequired>
        <FormLabel>Upload File</FormLabel>
        <Input type="file" accept=".xlsx" onChange={handleFileChange} multiple />
      </FormControl>
      <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading}>
        Submit
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <strong>Retailer:</strong> {selectedRetailer}
            </Text>
            <Text>
              <strong>Data Type:</strong> {importOption}
            </Text>
            <Text>
              <strong>File:</strong> {fileName}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={confirmSubmit}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Imports;
