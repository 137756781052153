import React, { useEffect, useState } from "react";
import { Button, Menu, MenuButton, MenuList, MenuItem, Image, Box, useTheme } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSupplierRetailer } from "../wrappers/SupplierRetailerContext";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";

const SupplierNavigation = () => {
  const theme = useTheme();
  const { selectedSupplier, setSelectedSupplier, user_supplier_access } = useSupplierRetailer();
  const [logos, setLogos] = useState({});

  const handleSupplierChange = (supplier) => {
    setSelectedSupplier(supplier);
  };

  useEffect(() => {
    const fetchLogos = async () => {
      const logoUrls = {};
      for (const supplier of user_supplier_access) {
        const logoRef = ref(storage, `logos/${supplier.value}.jpg`);
        try {
          const url = await getDownloadURL(logoRef);
          logoUrls[supplier.value] = url;
        } catch (error) {
          console.error("Error fetching logo:", error);
          // Use default logo if there is an error fetching the supplier logo
          const defaultLogoRef = ref(storage, `logos/blank.jpg`);
          try {
            const defaultUrl = await getDownloadURL(defaultLogoRef);
            logoUrls[supplier.value] = defaultUrl;
          } catch (defaultError) {
            console.error("Error fetching default logo:", defaultError);
          }
        }
      }
      setLogos(logoUrls);
    };

    fetchLogos();
  }, [user_supplier_access]);

  return (
    <Box display="flex" alignItems="center" m={1} width="calc(100% - 8px)" mx="4px">
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={"white"} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="black" maxWidth="100%" minWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" px={1} justifyContent="space-between">
          {selectedSupplier}
        </MenuButton>
        <MenuList bg={"white"} color="white" maxWidth="100%"   maxHeight="500px" overflowY="auto">
          {user_supplier_access.map((supplier) => (
            <MenuItem key={supplier.value} onClick={() => handleSupplierChange(supplier.value)} bg="white" _hover={{ bg: `${theme.colors.secondary2} !important`, color: "white !important" }} color="black" maxWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" minH="40px">
              {logos[supplier.value] && <Image boxSize="2rem" borderRadius="full" src={logos[supplier.value]} alt={supplier.text} mr="12px" />}
              <span>{supplier.text}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SupplierNavigation;
