import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, useToast, Box, Heading, Flex, Input, Button, Spinner, IconButton, useTheme } from "@chakra-ui/react";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import AddDcStore from "./AddDcStore"; // make sure the path is correct

const DcTable = () => {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState({ retailer: "", site_code: "" });
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-stores-dc`);
        setData(response.data.data);
        setFilteredData(response.data.data);
        setIsLoading(false);
      } catch (error) {
        toast({
          title: "Failed to load data",
          description: "Technical error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchTerm]);

  const filterData = () => {
    let filtered = data;
    if (searchTerm) {
      filtered = filtered.filter((store) => store.site_code.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    setFilteredData(filtered);
  };

  const deleteStore = async (site_code) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/delete-dc-store`, {
        site_code,
      });
      toast({
        title: "Store deleted",
        description: "The store has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      refreshData();
    } catch (error) {
      toast({
        title: "Failed to delete store",
        description: "An error occurred while deleting the store.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-stores-dc`);
      setData(response.data.data);
      setFilteredData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Failed to refresh data",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
    setEditRowData({ retailer: filteredData[index].retailer, site_code: filteredData[index].site_code });
  };

  const handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-dc-store`, {
        retailer: editRowData.retailer,
        site_code: editRowData.site_code,
        store_id: filteredData[editRowIndex].store_id,
      });
      toast({
        title: "Store updated",
        description: "The store has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditRowIndex(null);
      refreshData();
    } catch (error) {
      toast({
        title: "Failed to update store",
        description: "An error occurred while updating the store.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex>
      <Box flex="1" maxHeight="700px" overflowY="auto">
        <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
          DC Stores
        </Heading>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
            <Thead>
              <Tr>
                <Th>Retailer</Th>
                <Th>Site Code</Th>
                <Th>Delete</Th>
                <Th>Edit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData.map((row, index) => (
                <Tr key={index}>
                  <Td>{editRowIndex === index ? <Input value={editRowData.retailer} onChange={(e) => setEditRowData({ ...editRowData, retailer: e.target.value })} /> : row.retailer}</Td>
                  <Td>{editRowIndex === index ? <Input value={editRowData.site_code} onChange={(e) => setEditRowData({ ...editRowData, site_code: e.target.value })} /> : row.site_code}</Td>
                  <Td>
                    <IconButton icon={<FaTrash />} onClick={() => deleteStore(row.site_code)} colorScheme="red" variant="ghost" />
                  </Td>
                  <Td>{editRowIndex === index ? <IconButton icon={<FaSave />} onClick={handleSave} colorScheme="green" variant="ghost" /> : <IconButton icon={<FaEdit />} onClick={() => handleEdit(index)} colorScheme="blue" variant="ghost" />}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
      <Box ml={4}>
        <Input placeholder="Search" mb={4} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
          Add New DC Store
        </Button>
      </Box>
      <AddDcStore isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} refreshData={refreshData} />
    </Flex>
  );
};

export default DcTable;
