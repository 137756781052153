import React, { useState, useEffect } from "react";
import { Box, Heading, Flex, Spacer } from "@chakra-ui/react";
import Table1 from "./components/Table1";
import { useSupplierRetailer } from "../../wrappers/SupplierRetailerContext";
import Exports from "./components/Exports";
const Ranging = () => {
  const { selectedRetailer, selectedSupplier } = useSupplierRetailer();
  const [loading, setLoading] = useState(false);
  
  // useEffect(() => {
  //   setLoading(true);
  //   // Simulate a delay for fetching data
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, [selectedRetailer, selectedSupplier]);

  return (
    <Box overflow="hidden">
      <Heading size="lg" mb={1}>
        Ranging Report
      </Heading>
      {loading ? (
        <Box>Loading...</Box>
      ) : (
        <>
          <Box mb={4} display="flex" justifyContent="flex-start" alignItems="center">
            <Box position="absolute" zIndex={2000} mr={4}>
              <Exports supplier={selectedSupplier} retailer={selectedRetailer} />
            </Box>
            <Spacer />
          </Box>
          <Box maxHeight="800px" overflow="scroll" maxWidth="97vw">
            <Table1 supplier={selectedSupplier} retailer={selectedRetailer} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Ranging;
