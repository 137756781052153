import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, FormControl, FormLabel, Select, useToast, Flex, IconButton } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const EditSupplierAccess = () => {
  const { userid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [default_supplier, setDefault_supplier] = useState("");
  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [user, setUser] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-user-supplierAccess`, { userid });
        const { supplier_access, suppliers, user } = response.data.data;
        setDefault_supplier(user.default_supplier);
        setUser(user);
        setSelectedSuppliers(supplier_access.map((item) => item.suppliercode));
        setAvailableSuppliers(suppliers.map((item) => item.suppliercode));
      } catch (error) {
        toast({
          title: "Failed to load supplier access data",
          description: "An error occurred while loading data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, [userid, toast]);

  const handleDefaultSupplierChange = (e) => setDefault_supplier(e.target.value);

  const handleSupplierToggle = (supplier) => {
    setSelectedSuppliers((prev) => (prev.includes(supplier) ? prev.filter((item) => item !== supplier) : [...prev, supplier]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-user-supplierAccess`, {
        userid,
        default_supplier,
        suppliers: selectedSuppliers,
      });
      toast({
        title: "Supplier access updated",
        description: "The supplier access information was successfully updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/users");
    } catch (error) {
      toast({
        title: "Failed to update supplier access",
        description: "An error occurred while updating supplier access",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box width="500px" margin="50px auto" textAlign="center" padding="4" boxShadow="md" borderRadius="md" backgroundColor="white">
      <Flex justify="space-between" mb={4} align="center">
        <IconButton icon={<FaArrowLeft />} colorScheme="teal" onClick={() => navigate("/users")} aria-label="Go back" />
      </Flex>
      <form onSubmit={handleSubmit}>
        <FormLabel textAlign="center" width="100%">{user.username}</FormLabel>
        <FormControl isRequired>
          <FormLabel>Default Supplier</FormLabel>
          <Select value={default_supplier} onChange={handleDefaultSupplierChange} placeholder="Select supplier">
            {availableSuppliers.map((supplier) => (
              <option key={supplier} value={supplier}>
                {supplier}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl mt="2">
          <FormLabel>Supplier Access</FormLabel>
          <Box>
            {availableSuppliers.map((supplier) => (
              <Button key={supplier} variant={selectedSuppliers.includes(supplier) ? "solid" : "outline"} colorScheme={selectedSuppliers.includes(supplier) ? "green" : "gray"} mr={2} mb={2} onClick={() => handleSupplierToggle(supplier)}>
                {supplier}
              </Button>
            ))}
          </Box>
        </FormControl>

        <Box mt={4}>
          <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
            Submit
          </Button>
          <Button onClick={() => navigate("/users")} colorScheme="gray" ml={4}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditSupplierAccess;
