import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Box, Spinner, useToast, Heading, useTheme } from "@chakra-ui/react";

const LoginHistory = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const theme = useTheme();
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-login-history`);
        console.log(response.data);
        setHistory(response.data.history);
      } catch (error) {
        toast({
          title: "Error fetching login history",
          description: "An error occurred while retrieving the login history",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [toast]);

  function formatDateString(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight

    return `${day} ${month} '${year} ${hours} ${ampm}`;
  }

  return (
    <Box width="400px" margin="0 auto" mt="20px" maxHeight="800px" overflowY="auto">
      <Heading size="md" textAlign="center" mt={12}  bg={theme.colors.primary2} color={"white"} p={1}>
        Login History
      </Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table variant="striped" colorScheme="blue">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Login Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {history.map((record, index) => (
              <Tr key={index}>
                <Td>{record.username}</Td>
                <Td>{formatDateString(record.login_date)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default LoginHistory;
