import React, { useEffect, useState } from "react";
import { chakra, Box, Table, Thead, Tbody, Tr, Th, Td, Select, Input, Flex, Spacer, useTheme, Heading, Spinner } from "@chakra-ui/react";
import axios from "axios";
import Exports from "./Exports";
const TableComment = ({ selectedRetailer, selectedSupplier, weekEndingDates, ddStrikeRate }) => {
  const [TheadData, setTheadData] = useState([]);
  const [TbodyData, setTbodyData] = useState([]);

  const [week_ending_date, setWeek_ending_date] = useState(weekEndingDates[0].week_ending_date || "");
  const [comment, setComment] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const fetchTableComment = async (weekEndingDate, comment, search) => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_strikeRate/get-table-comment`, {
        retailer: selectedRetailer,
        supplier: selectedSupplier,
        week_ending_date: weekEndingDate,
        comment: comment,
        search: search,
      });
      setTheadData(res.data.data.tableData.thead);
      setTbodyData(res.data.data.tableData.tbody);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching comment table data:", error);
      setLoading(false);
    }
  };

  const handleFiltersChange = async (date, comment, search) => {
    setWeek_ending_date(date);
    setComment(comment);
    setSearch(search);
    fetchTableComment(date, comment, search);
  };

  useEffect(() => {
    if (weekEndingDates.length > 0) {
      fetchTableComment(week_ending_date, comment, search);
    }
  }, [selectedRetailer, selectedSupplier, weekEndingDates]);

  const postReason = async (row) => {
    const payload = {
      retailer: selectedRetailer,
      supplier: selectedSupplier,
      week_ending_date: row.week_ending_date,
      site_code: row.site_code,
      article_code: row.article_code,
      reason_uuid: row.reason_uuid,
    };
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_strikeRate/save-reason`, payload);
      if (!res.data.status) {
        console.warn("Failed to update reason. Response:", res);
      }
    } catch (error) {
      console.error("Error updating reason:", error);
    }
  };

  const handleReasonChange = async (e, row) => {
    const reason_uuid = e.target.value;
    const updatedRow = { ...row, reason_uuid };
    await postReason(updatedRow);
    setTbodyData((prevData) => prevData.map((r) => (r.week_ending_date === row.week_ending_date && r.site_code === row.site_code && r.article_code === row.article_code ? updatedRow : r)));
  };

  function formatDateString(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);
  
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
  
    return `${day} ${month} '${year}`;
  }

  if (loading) return <Spinner />;

  const CustomSelect = chakra(Select, {
    baseStyle: {
      paddingY: "0px", // Remove vertical padding
      fontSize: "xs", // Adjust font size if needed
      lineHeight: "1",
      height: "auto",
      minHeight: "auto",
    },
  });

  return (
    <Box width="100%" mb={4}>
      <Heading size="md" textAlign="center" mb={4} bg={theme.colors.primary2} color={"white"} p={1}>
        Comment Section
      </Heading>
      <Box mb={4} display="flex" justifyContent="flex-start" alignItems="center">
        <Box position="absolute" zIndex={2000} mr={4}>
          <Exports supplier={selectedSupplier} retailer={selectedRetailer} week_ending_date={week_ending_date} dd_uuid={comment} />
        </Box>
        <Box size="md" width="400px" ml={32}>
          <Select id="comment-date-dropdown" value={week_ending_date} onChange={(e) => handleFiltersChange(e.target.value, document.getElementById("comment-dropdown").value, document.getElementById("comment-search").value)}>
            {weekEndingDates.map((date, index) => (
              <option key={index} value={date.week_ending_date}>
                {formatDateString(date.week_ending_date)}
              </option>
            ))}
          </Select>
        </Box>
        <Box size="md" width="400px" ml={4}>
          <Select id="comment-dropdown" onChange={(e) => handleFiltersChange(document.getElementById("comment-date-dropdown").value, e.target.value, document.getElementById("comment-search").value)} value={comment}>
            <option value="">All Reasons</option>
            {/* <option value="empty">Empty</option> */}
            {ddStrikeRate.map((item) => (
              <option key={item.uuid} value={item.uuid}>
                {item.text}
              </option>
            ))}
          </Select>
        </Box>
        <Box size="md" width="400px" ml={4}>
          <Input id="comment-search" placeholder="Search .." onKeyPress={(e) => e.key === "Enter" && handleFiltersChange(document.getElementById("comment-date-dropdown").value, document.getElementById("comment-dropdown").value, e.target.value)} />
        </Box>
      </Box>
      <Box overflowY="auto" maxHeight="600px">
        <Table mt={4} variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
          <Thead>
            {TheadData.map((row, index) => (
              <Tr key={index}>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th1}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th2}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th3}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th4}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th5}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th6}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th7}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th8}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th9}
                </Th>
                <Th fontSize="xs" whiteSpace="nowrap">
                  {row.th10}
                </Th>
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {TbodyData.map((row, index) => (
              <Tr key={index}>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {formatDateString(row.week_ending_date)}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.po_code}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.site_code}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.site_description}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.article_code}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.article_description}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.order_qty}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.receipt_qty}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap">
                  {row.strike_rate_percentage}
                </Td>
                <Td fontSize="xs" whiteSpace="nowrap" p={0}>
                  <CustomSelect defaultValue={row.reason_uuid} onChange={(e) => handleReasonChange(e, row)}>
                    {ddStrikeRate.map((item) => (
                      <option key={item.uuid} value={item.uuid}>
                        {item.text}
                      </option>
                    ))}
                  </CustomSelect>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default TableComment;
