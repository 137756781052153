import React, { useState } from "react";
import { Box, Button, FormControl, FormLabel, Text, useToast, Flex } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const StrikeRate = ({ supplier, retailer }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const toast = useToast();

  const getFirstDayOfNextMonth = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 1);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async () => {
    const formattedStartDate = formatDate(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
    const formattedEndDate = formatDate(getFirstDayOfNextMonth(endDate));

    const data = {
      retailer: retailer,
      supplier: supplier,
      sDate: formattedStartDate,
      eDate: formattedEndDate,
    };
    toast({
        title: "Backend Response",
        description: "Not implemented yet!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/delete-strikerate`, data);
      console.log("response", response.data);
      toast({
        title: "Backend Response",
        description: response.data.message,
        status: response.data.status,
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log({ error });
      toast({
        title: "Error",
        description: "Failed to delete Strike Rate data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      <Box boxShadow="lg" p={8} width="400px">
        <FormControl mb={4}>
          <FormLabel>Selected Retailer</FormLabel>
          <Text>{retailer}</Text>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Selected Supplier</FormLabel>
          <Text>{supplier}</Text>
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Start Date</FormLabel>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy/MM"
            showMonthYearPicker
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>End Date</FormLabel>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy/MM"
            showMonthYearPicker
          />
        </FormControl>
        <Button onClick={handleSubmit} colorScheme="blue">
          Submit
        </Button>
      </Box>
    </Flex>
  );
};

export default StrikeRate;
