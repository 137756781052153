import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Box, Button, IconButton, Input, useToast, Spinner, useTheme, Switch } from "@chakra-ui/react";
import { FaEdit, FaSave, FaPlus } from "react-icons/fa";

const Checkers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(null);
  const [editForm, setEditForm] = useState({});
  const [adding, setAdding] = useState(false);
  const toast = useToast();
  const theme = useTheme();

  useEffect(() => {
    const fetchEdiData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-edi`, {
          retailer: "cli",
        });
        setData(response.data.data);
      } catch (error) {
        toast({
          title: "Error fetching EDI data",
          description: "An error occurred while retrieving the EDI data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchEdiData();
  }, [toast]);

  const handleEditClick = (index) => {
    setEditing(index);
    setEditForm(data[index]);
  };

  const handleSaveClick = async (index) => {
    try {
      if (adding) {
        // Insert new record in the backend
        await axios.post(`${process.env.REACT_APP_API_URL}/admin/add-edi`, {
          retailer: "cli",
          ...editForm,
        });

        setData([...data, editForm]);
        setAdding(false);
      } else {
        // Update the data in the backend
        await axios.post(`${process.env.REACT_APP_API_URL}/admin/update-edi`, {
          retailer: "cli",
          ...editForm,
        });

        const newData = [...data];
        newData[index] = editForm;
        setData(newData);
      }

      setEditing(null);
      toast({
        title: "Success",
        description: "EDI data saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error saving EDI data",
        description: "An error occurred while saving the EDI data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };

  const handleAddClick = () => {
    setEditForm({
      username: "",
      password: "",
      country: "",
      supplier: "",
      DC: false,
    });
    setAdding(true);
    setEditing(data.length); // Setting editing to the index of the new row
  };

  return (
    <Box width="100%" overflowX="auto" mt={4}>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table variant="striped" colorScheme="blue">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Password</Th>
              <Th>Country</Th>
              <Th>Supplier</Th>
              <Th>DC</Th>
              <Th>Edit</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((record, index) => (
              <Tr key={record.username}>
                <Td>{editing === index ? <Input name="username" value={editForm.username} onChange={handleInputChange} /> : record.username}</Td>
                <Td>{editing === index ? <Input name="password" value={editForm.password} onChange={handleInputChange} /> : record.password}</Td>
                <Td>{editing === index ? <Input name="country" value={editForm.country} onChange={handleInputChange} /> : record.country}</Td>
                <Td>{editing === index ? <Input name="supplier" value={editForm.supplier} onChange={handleInputChange} /> : record.supplier}</Td>
                <Td>{editing === index ? <Switch isChecked={editForm.DC} onChange={(e) => handleInputChange({ target: { name: "DC", value: e.target.checked } })} /> : record.DC ? "Yes" : "No"}</Td>
                <Td>{editing === index ? <IconButton icon={<FaSave />} onClick={() => handleSaveClick(index)} /> : <IconButton icon={<FaEdit />} onClick={() => handleEditClick(index)} />}</Td>
              </Tr>
            ))}
            {adding && (
              <Tr>
                <Td>
                  <Input name="username" value={editForm.username} onChange={handleInputChange} />
                </Td>
                <Td>
                  <Input name="password" value={editForm.password} onChange={handleInputChange} />
                </Td>
                <Td>
                  <Input name="country" value={editForm.country} onChange={handleInputChange} />
                </Td>
                <Td>
                  <Input name="supplier" value={editForm.supplier} onChange={handleInputChange} />
                </Td>
                <Td>
  <Switch
    isChecked={editForm.DC}
    onChange={(e) => handleInputChange({ target: { name: 'DC', value: e.target.checked } })}
  />
</Td>
                <Td>
                  <IconButton icon={<FaSave />} onClick={() => handleSaveClick(data.length)} />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      )}
      <Button leftIcon={<FaPlus />} colorScheme="teal" mt={4} onClick={handleAddClick}>
        Add New EDI
      </Button>
    </Box>
  );
};

export default Checkers;
