import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Box, Select, Table, Thead, Tbody, Tr, Th, Td, Button, useToast, Flex, Text, Stack } from "@chakra-ui/react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import EmailForm from "./components/EmailForm";
import EditablePopover from "./components/EditablePopover";
import { useSupplierRetailer } from "../../../wrappers/SupplierRetailerContext";

const Users = () => {
  const { fetchSupplierAccess } = useSupplierRetailer();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [user_type, setUser_type] = useState([]);
  const [searchTerm, setSearchTerm] = useState({ fname: "", username: "" });
  const [filters, setFilters] = useState({ activeyn: false, user_type: "ALL" });

  const handleAddUser = () => {
    navigate("/addUser");
  };

  const handle_editPageAccess = async (userid) => {
    localStorage.setItem("userFilter", filters.user_type);
    navigate(`/editPageAccess/${userid}`);
  };

  const handle_editRetailerAccess = async (userid) => {
    localStorage.setItem("userFilter", filters.user_type);
    navigate(`/editRetailerAccess/${userid}`);
  };

  const handle_editSupplierAccess = async (userid) => {
    localStorage.setItem("userFilter", filters.user_type);
    navigate(`/editSupplierAccess/${userid}`);
  };

  const handleDeleteUser = async (userid) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/del-user`, { userid });
      console.log(response);
      toast({
        title: response.data.title,
        description: response.data.description,
        status: response.data.status,
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Failed to Delete user",
        description: "An error occurred while deleting the user",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      fetchUsers();
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-users`, {});
      const { users, message, description, user_type } = response.data;

      toast({
        title: message,
        description: description,
        status: "success",
        duration: 500,
        isClosable: true,
      });
      setUsers(users);
      setUser_type(user_type);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast({
        title: "Failed to load users",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredData = users.filter((row) => {
    try {
      // Apply the user_type filter only if it's not 'ALL'
      if (filters.user_type !== "ALL" && row.user_type !== filters.user_type) return false;

      // If no filter is applied or user_type is 'ALL', include the user in the filtered data
      return true;
    } catch (error) {
      console.log(row);
      return false;
    }
  });
  useEffect(() => {
    const storedFilter = localStorage.getItem("userFilter");
    if (storedFilter) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        user_type: storedFilter,
      }));
      localStorage.removeItem("userFilter");
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        user_type: "ALL",
      }));
    }
    fetchUsers();
    fetchSupplierAccess();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
    }).format(date);
  };

  const reduce__subscriptionAmount = (value) => {
    return value / 100;
  };
  const induce__subscriptionAmount = (value) => {
    return value * 100;
  };

  const submit_activeyn = async (userid, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-user-activeyn`, {
        userid,
        activeyn: newStatus,
      });

      toast({
        title: "User status updated",
        description: `The user is now ${newStatus ? "Active" : "Inactive"}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      fetchUsers(); // Refresh the user list
    } catch (error) {
      toast({
        title: "Failed to update user status",
        description: "An error occurred while updating user status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submit_restrictedyn = async (userid, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-user-restrictedyn`, {
        userid,
        restrictedyn: newStatus,
      });

      toast({
        title: "restrcited status updated",
        description: `The user now has ${newStatus ? "Restricted Access" : "Unrestricted Access"}`,
        status: "success",
        duration: 7000,
        isClosable: true,
      });

      fetchUsers(); // Refresh the user list
    } catch (error) {
      toast({
        title: "Failed to update user status",
        description: "An error occurred while updating user status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submit_subscriptionyn = async (userid, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-user-subscriptionyn`, {
        userid,
        subscriptionyn: newStatus,
      });

      toast({
        title: "subscription status updated",
        description: `The user's Subscription is now ${newStatus ? "Active" : "Trial Period"}`,
        status: "success",
        duration: 7000,
        isClosable: true,
      });

      fetchUsers(); // Refresh the user list
    } catch (error) {
      toast({
        title: "Failed to update user status",
        description: "An error occurred while updating user status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submit_user_type = async (userid, newUserType) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-user-type`, {
        userid,
        user_type: newUserType,
      });

      toast({
        title: "User type updated",
        description: `The user type has been successfully updated to ${newUserType}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      fetchUsers(); // Refresh the user list if necessary
    } catch (error) {
      toast({
        title: "Failed to update user type",
        description: "An error occurred while updating the user type",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submit_useremail = async (userid, useremail) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-useremail`, {
        userid,
        useremail,
      });
      toast({
        title: "update successful!",
        description: `The email has been successfully updated.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchUsers(); // Refresh the user list if necessary
    } catch (error) {
      toast({
        title: "Failed to update email",
        description: "An error occurred while updating the email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submit_fname = async (userid, fname) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-fname`, {
        userid,
        fname,
      });
      toast({
        title: "update successful!",
        description: `The email has been successfully updated.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchUsers(); // Refresh the user list if necessary
    } catch (error) {
      toast({
        title: "Failed to update email",
        description: "An error occurred while updating the email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submit_username = async (userid, username) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-username`, {
        userid,
        username,
      });
      toast({
        title: "update successful!",
        description: `The email has been successfully updated.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchUsers(); // Refresh the user list if necessary
    } catch (error) {
      toast({
        title: "Failed to update email",
        description: "An error occurred while updating the email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const submit_userpass = async (userid, userpass) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-userpass`, {
        userid,
        userpass,
      });
      toast({
        title: "update successful!",
        description: `The email has been successfully updated.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchUsers(); // Refresh the user list if necessary
    } catch (error) {
      toast({
        title: "Failed to update email",
        description: "An error occurred while updating the email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const submit_subscription_amount = async (userid, subscription_amount) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-subscription_amount`, {
        userid,
        subscription_amount: induce__subscriptionAmount(subscription_amount),
      });
      toast({
        title: "update successful!",
        description: `The email has been successfully updated.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchUsers(); // Refresh the user list if necessary
    } catch (error) {
      toast({
        title: "Failed to update email",
        description: "An error occurred while updating the email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const send_email = async (userid, subject, body) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/email-user`, {
        userid,
        email: { subject, body },
      });
      toast({
        title: "Email Sent",
        description: `The email has been sent successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to Send Email",
        description: "An error occurred while sending the email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const displayDate = (datetime) => {
    if (!datetime || datetime == null || datetime == undefined) {
      return "";
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(datetime);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight

    return `${day} ${month} '${year} `;
  };

  return (
    <Box overflowX="auto" mt={16}>
      <Flex justify="flex-start" mb={4} gap={4}>
        <Flex align="center">
          <Stack direction="row" spacing={4}>
            <Button leftIcon={<FaPlus />} colorScheme="blue" variant="solid" onClick={handleAddUser}>
              Add User
            </Button>
            <Select placeholder="Filter by User Type" value={filters.user_type} onChange={(e) => setFilters({ ...filters, user_type: e.target.value })} width="200px">
              <option value="ALL">ALL</option>
              <option value="admin">Admin's</option>
              <option value="supplier">Supplier's</option>
              <option value="SME">SME's</option>
            </Select>
          </Stack>
        </Flex>
        <Text>Total Users: {filteredData.length}</Text>
      </Flex>
      <Box maxHeight="700px" overflowY="auto">
        <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md" textStyle="smallText">
          <Thead>
            <Tr>
              <Th px={2} py={1}>
                userid
              </Th>
              <Th px={1} py={1}>
                Names
              </Th>
              <Th px={1} py={1}>
                Username
              </Th>
              <Th px={1} py={1}>
                Email
              </Th>
              <Th px={1} py={1}>
                password
              </Th>
              <Th px={1} py={1}>
                User type
              </Th>
              <Th px={1} py={1}>
                Subscription Amount
              </Th>
              <Th px={1} py={1}>
                Date last paid
              </Th>

              <Th px={1} py={1}>
                Signin
              </Th>
              <Th px={1} py={1}>
                Restricted
              </Th>
              <Th px={1} py={1}>
                Subscription
              </Th>

              <Th px={1} py={1}>
                Page Access
              </Th>
              <Th px={1} py={1}>
                Retailer Access
              </Th>
              <Th px={1} py={1}>
                Supplier Access
              </Th>
              <Th px={1} py={1}>
                Edit
              </Th>
              <Th px={1} py={1}>
                Delete
              </Th>

              <Th px={1} py={1}>
                date created
              </Th>
              <Th px={1} py={1}>
                Subscription date
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((row) => (
              <Tr key={row.id}>
                <Td>{row.userid}</Td>
                <Td>
                  <EditablePopover label="First Name" value={row.fname} fieldName="fname" onSubmit={(fieldName, newValue) => submit_fname(row.userid, newValue)} />
                </Td>
                <Td>
                  <EditablePopover label="Username" value={row.username} fieldName="username" onSubmit={(fieldName, newValue) => submit_username(row.userid, newValue)} />
                </Td>
                <Td>
                  <EditablePopover label="Email" value={row.useremail} fieldName="useremail" onSubmit={(fieldName, newValue) => submit_useremail(row.userid, newValue)} />
                </Td>
                <Td>
                  <EditablePopover label="Password" value={row.userpass} fieldName="userpass" onSubmit={(fieldName, newValue) => submit_userpass(row.userid, newValue)} />
                </Td>
                <Td px={1} py={1}>
                  <Select
                    value={row.user_type}
                    onChange={(e) => submit_user_type(row.userid, e.target.value)}
                    size="sm" // Make the dropdown smaller
                    width="150px" // Adjust the width as needed
                  >
                    {user_type.map((type) => (
                      <option key={type} value={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td px={1} py={1}>
                  <EditablePopover label="subscription_amount" value={reduce__subscriptionAmount(row.subscription_amount)} fieldName="subscription_amount" onSubmit={(fieldName, newValue) => submit_subscription_amount(row.userid, newValue)} />
                </Td>
                <Td px={1} py={1}>
                  {displayDate(row.date_last_paid)}
                </Td>
                <Td px={1} py={1}>
                  <Button size="sm" onClick={() => submit_activeyn(row.userid, row.activeyn)} colorScheme={row.activeyn ? "green" : "red"}>
                    {row.activeyn ? "YES" : "NO"}
                  </Button>
                </Td>
                <Td px={1} py={1}>
                  <Button size="sm" onClick={() => submit_restrictedyn(row.userid, row.restrictedyn)} colorScheme={row.restrictedyn ? "green" : "red"}>
                    {row.restrictedyn ? "YES" : "NO"}
                  </Button>
                </Td>
                <Td px={1} py={1}>
                  <Button size="sm" onClick={() => submit_subscriptionyn(row.userid, row.subscriptionyn)} colorScheme={row.subscriptionyn ? "green" : "red"}>
                    {row.subscriptionyn ? "YES" : "NO"}
                  </Button>
                </Td>
                <Td px={1} py={1}>
                  <Button size="sm" onClick={() => handle_editPageAccess(row.userid)} leftIcon={<FaEdit color={"blue"} />}>
                    Edit
                  </Button>
                </Td>
                <Td px={1} py={1}>
                  <Button size="sm" onClick={() => handle_editRetailerAccess(row.userid)} leftIcon={<FaEdit color={"blue"} />}>
                    Edit
                  </Button>
                </Td>
                <Td px={1} py={1}>
                  <Button size="sm" onClick={() => handle_editSupplierAccess(row.userid)} leftIcon={<FaEdit color={"blue"} />}>
                    Edit
                  </Button>
                </Td>
                <Td px={1} py={1}>
                  <EmailForm userid={row.userid} onSendEmail={send_email} />
                </Td>
                <Td px={1} py={1}>
                  <Button size="sm" onClick={() => handleDeleteUser(row.userid)} leftIcon={<FaTrash color={"red"} />}>
                    Delete
                  </Button>
                </Td>

                <Td px={1} py={1}>
                  {displayDate(row.createddate)}
                </Td>
                <Td px={1} py={1}>
                  {displayDate(row.subscription_sdate)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Users;
