import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Input, FormControl, FormLabel, useToast } from '@chakra-ui/react';

const Account = ({supplier}) => {
  const userid = localStorage.getItem("userid");
    const [formData, setFormData] = useState({
        userid:'',
        fname: '',
        username: '',
        useremail: '',
        userpass: '',
    });

    const toast = useToast();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/myProfile/get-account`,{userid,supplier});
                console.log(response.data.data);
                setFormData(response.data.data);
            } catch (error) {
                toast({
                    title: 'Error',
                    description: 'Failed to load user data',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchUserData();
    }, [supplier]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          formData.userid=userid;
          console.log(formData)
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/myProfile/edit-account`, formData);
            toast({
                title: response.data.title,
                description: response.data.description,
                status: response.data.status,
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update user data',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <FormControl mb={4}>
                <FormLabel>First Name</FormLabel>
                <Input
                    name="fname"
                    value={formData.fname}
                    onChange={handleChange}
                    placeholder="First Name"
                />
            </FormControl>

            <FormControl mb={4}>
                <FormLabel>Username</FormLabel>
                <Input
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    placeholder="Username"
                />
            </FormControl>

            <FormControl mb={4}>
                <FormLabel>Email</FormLabel>
                <Input
                    name="useremail"
                    value={formData.useremail}
                    onChange={handleChange}
                    placeholder="Email"
                    type="email"
                />
            </FormControl>

            <FormControl mb={4}>
                <FormLabel>Password</FormLabel>
                <Input
                    name="userpass"
                    value={formData.userpass}
                    onChange={handleChange}
                    placeholder="Password"
                    type="text"
                />
            </FormControl>

            <Button type="submit" colorScheme="teal" mt={4}>
                Save Changes
            </Button>
        </Box>
    );
};

export default Account;
