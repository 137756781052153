export const interpolateColors = (color1, color2, factor) => {
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
  }
  return result;
};

export const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

export const rgbToHex = (rgb) => {
  return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
};

export const generateColorPalette = (startColor, endColor, steps) => {
  const start = hexToRgb(startColor);
  const end = hexToRgb(endColor);
  const palette = [];
  for (let i = 0; i < steps; i++) {
    const factor = i / (steps - 1);
    palette.push(rgbToHex(interpolateColors(start, end, factor)));
  }
  return palette;
};
