import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Box, Button, IconButton, Input, useToast, Spinner, useTheme, Switch } from "@chakra-ui/react";
import { FaEdit, FaSave, FaPlus,FaTrash } from "react-icons/fa";

const Dischem_sales = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(null);
  const [editForm, setEditForm] = useState({});
  const [adding, setAdding] = useState(false);
  const toast = useToast();
  const theme = useTheme();

  useEffect(() => {
    const fetchEdiData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/spearbi-edi/get-dischem-sales`, {});
        setData(response.data.data);
      } catch (error) {
        toast({
          title: "Error fetching EDI data",
          description: "An error occurred while retrieving the EDI data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchEdiData();
  }, [toast]);

  const handleEditClick = (index) => {
    setEditing(index);
    setEditForm(data[index]);
  };

  const handleSaveClick = async (index) => {
    try {
      if (adding) {
        // Insert new record in the backend
        await axios.post(`${process.env.REACT_APP_API_URL}/spearbi-edi/update`, {
          tbl: "dischem_sales",
          ...editForm,
          id:index+1
        });

        setData([...data, editForm]);
        setAdding(false);
      } else {
        // Update the data in the backend
        await axios.post(`${process.env.REACT_APP_API_URL}/spearbi-edi/update`, {
          tbl: "dischem_sales",
          ...editForm,
          id: editForm.id ? editForm.id : index + 1,
        });

        const newData = [...data];
        newData[index] = editForm;
        setData(newData);
      }

      setEditing(null);
      toast({
        title: "Success",
        description: "EDI data saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error saving EDI data",
        description: "An error occurred while saving the EDI data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleDeleteClick = async (index, id) => {
    try {
      // Delete the record in the backend
      await axios.post(`${process.env.REACT_APP_API_URL}/spearbi-edi/delete`, {
        tbl: "dischem_sales",
        id: id,
      });
  
      // Update the data in the frontend
      const newData = [...data];
      newData.splice(index, 1);
      setData(newData);
  
      toast({
        title: "Success",
        description: "EDI data deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting EDI data",
        description: "An error occurred while deleting the EDI data",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };

  const handleAddClick = () => {
    setEditForm({
      username: "",
      password: "",
      supplier: "",
      id:data.length+1
    });
    setAdding(true);
    setEditing(data.length); // Setting editing to the index of the new row
  };

  return (
    <Box width="100%" overflowX="auto" mt={4}>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table variant="striped" colorScheme={theme.colors.primary1}>
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Password</Th>
              <Th>Supplier</Th>
              <Th>Edit</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((record, index) => (
              <Tr key={record.id}>
                <Td>{editing === index ? <Input name="username" value={editForm.username} onChange={handleInputChange} /> : record.username}</Td>
                <Td>{editing === index ? <Input name="password" value={editForm.password} onChange={handleInputChange} /> : record.password}</Td>
                <Td>{editing === index ? <Input name="supplier" value={editForm.supplier} onChange={handleInputChange} /> : record.supplier}</Td>
                <Td>{editing === index ? <IconButton icon={<FaSave />} onClick={() => handleSaveClick(index)} /> : <IconButton icon={<FaEdit />} onClick={() => handleEditClick(index)} />}</Td>
                <Td> <IconButton icon={<FaTrash />} onClick={() => handleDeleteClick(index,record.id)} /></Td>
              </Tr>
            ))}
            {adding && (
              <Tr>
                <Td>
                  <Input name="username" value={editForm.username} onChange={handleInputChange} />
                </Td>
                <Td>
                  <Input name="password" value={editForm.password} onChange={handleInputChange} />
                </Td>
                <Td>
                  <Input name="supplier" value={editForm.supplier} onChange={handleInputChange} />
                </Td>
                <Td>
                  <IconButton icon={<FaSave />} onClick={() => handleSaveClick(data.length)} />
                </Td>
                <Td></Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      )}
      <Button leftIcon={<FaPlus />} colorScheme="teal" mt={4} onClick={handleAddClick}>
        Add New EDI
      </Button>
    </Box>
  );
};

export default Dischem_sales;
