import React from "react";
import { Box, Heading, Text, Flex } from "@chakra-ui/react";

const retailers = ["PnP", "Checkers", "Dischem", "Clicks", "Makro"];

const About = () => {
  return (
    <div>
      <Box maxW="600px" mx="auto" mt="10" p="5" boxShadow="lg" borderRadius="md">
        <Heading as="h2" size="xl" mb="4">
          About Spearbi
        </Heading>
        <Text fontSize="lg" mb="4">
          Spearbi is designed to provide suppliers with comprehensive insights into their business performance.
        </Text>
        <Text fontSize="md" mb="2">
          <strong>Sales:</strong> Analyze your sales data to track growth and identify trends.
        </Text>
        <Text fontSize="md" mb="2">
          <strong>Strike Rate:</strong> Monitor and improve your delivery performance to enhance customer satisfaction.
        </Text>
        <Text fontSize="md" mb="2">
          <strong>Data Imports and Exports:</strong> Easily import and export data for detailed analysis and reporting.
        </Text>
        <Text fontSize="md" mb="2">
          <strong>EDI Integration:</strong> Seamlessly integrate with PnP for automated data updates.
        </Text>
        <Text fontSize="md" mb="2">
          <strong>User Management:</strong> Manage user access and permissions to ensure secure and efficient operations.
        </Text>
      </Box>
      <Box maxW="600px" mx="auto" mt="4" p="2">
        <Heading as="h3" size="lg" mb="4">
          Our Retailers
        </Heading>
        <Flex wrap="wrap" justifyContent="flex-start">
          {retailers.map((retailer, index) => (
            <Box key={index} m="2" p="4" boxShadow="md" borderRadius="md">
              {retailer}
            </Box>
          ))}
        </Flex>
      </Box>
    </div>
  );
};

export default About;
