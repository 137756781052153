import React, { useState, useRef } from "react";
import { Button, FormControl, FormLabel, Input, Stack, useDisclosure, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton } from "@chakra-ui/react";
import FocusLock from "react-focus-lock";

const EmailForm = ({ userid, onSendEmail }) => {
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const firstFieldRef = useRef(null);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleSendEmail = () => {
    onSendEmail(userid, emailSubject, emailBody);
    onClose();
  };

  return (
    <>
      <Popover isOpen={isOpen} initialFocusRef={firstFieldRef} onOpen={onOpen} onClose={onClose} placement="top" closeOnBlur={false}>
        <PopoverTrigger>
          <Button ml={2} colorScheme="blue">
            Send Email
          </Button>
        </PopoverTrigger>
        <PopoverContent p={5}>
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverArrow />
            <PopoverCloseButton />
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Subject</FormLabel>
                <Input ref={firstFieldRef} value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Body</FormLabel>
                <Input value={emailBody} onChange={(e) => setEmailBody(e.target.value)} />
              </FormControl>
              <Button size="sm" colorScheme="orange" onClick={handleSendEmail}>
                Send
              </Button>
            </Stack>
          </FocusLock>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default EmailForm;
