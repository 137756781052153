// ChakraWrapper.js
import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  components: {
    Button: {
      variants: {
        active: {
          borderColor: "primary1",
          borderWidth: "2px",
          color: "primary1",
        },
      },
    },
  },
  colors: {
    primary1: "#58b6c0",
    primary2: "#04aec1",
    secondary1: "#58c087",
    secondary2: "#74ebd5",
    textActive:"white",
    textInactive:"black",
    gradients: {
      primary: "linear(to-r, #58b6c0, #04aec1)",
      secondary: "linear(to-r, #04aec1, #58b6c0)",
    },
    custom: {
      50: "#FFFFFF",
      100: "#f2f2f2",
      200: "#dbdbdb",
      300: "#eaeaea",
      400: "#D7DDE8",
      500: "#eef2f3",
    },
  },
  textStyles: {
    smallText: {
      fontSize: "8px",
    },
  },
});
const ChakraWrapper = ({ children }) => {
  return <ChakraProvider theme={customTheme}>{children}</ChakraProvider>;
};

export default ChakraWrapper;
