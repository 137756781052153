import React, { useEffect, useState, useRef } from "react";
import { Box, Spinner, Heading,useTheme, } from "@chakra-ui/react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import ChartJS from "../../../tools/ChartSetup"; // Import the registered ChartJS with data labels and gradient plugin
import gradient from "chartjs-plugin-gradient";

const colorPalette = ["#12a3c4", "#1253c4", "#f5a623", "#7ed321", "#d0021b", "#9013fe", "#4a90e2", "#50e3c2"];

const Chart1 = ({ supplier, retailer }) => {
  const theme = useTheme();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/rep_sales/chart1`, {
          supplier,
          retailer,
          userid: localStorage.getItem("userid"),
        });
        const data = res.data.data;
  
        const ctx = chartRef.current?.ctx;
        const chartData = {
          labels: data.map((item) => item.site),
          datasets: [
            {
              data: data.map((item) => item.value),
              backgroundColor: data.map((_, index) => {
                if (ctx) {
                  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                  gradient.addColorStop(0, colorPalette[index % colorPalette.length]);
                  gradient.addColorStop(1, "#ffffff");
                  return gradient;
                }
                return colorPalette[index % colorPalette.length];
              }),
              borderColor: "rgba(219,219,219, 1)",
              borderWidth: 2,
            },
          ],
        };
        setChartData(chartData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };
  
    fetchData();
  
    return () => {
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, [supplier, retailer]); // Add supplier and retailer as dependencies
  

  if (loading) return <Spinner />;

  return (
    <Box height="30vh">
      <Heading size="md" textAlign="center" mb={4}  bg={theme.colors.primary2} color={"white"}  p={1}>
        by Site Profile
      </Heading>
      {chartData && (
        <Pie
          ref={chartRef}
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: true,
                color: "#000",
                backgroundColor: "#fff",
                borderRadius: 3,
                padding: 6,
                formatter: function (value) {
                  if (typeof value === "number" ||typeof value === "string" ) {
                    return `R${parseFloat(value).toLocaleString()}`;
                  }
                  return value;
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.label || "";
                    if (label) {
                      label += ": ";
                    }
                    if (context.raw !== null) {
                      label += `R${context.raw.toLocaleString()}`;
                    }
                    return label;
                  },
                },
              },
              filler: { propagate: false },
            },
          }}
        />
      )}
    </Box>
  );
};

export default Chart1;
