import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Switch, Box, Spinner, IconButton, Input, Button, useToast } from "@chakra-ui/react";
import { FaEdit, FaSave } from "react-icons/fa";
import { useSupplierRetailer } from "../../../wrappers/SupplierRetailerContext";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState({ brand: "", articleunitcost: "", articledescription: "" });
  const { selectedRetailer, selectedSupplier } = useSupplierRetailer();
  const toast = useToast();

  useEffect(() => {
    if (selectedSupplier && selectedRetailer) {
      fetchArticles();
    }
  }, [selectedSupplier, selectedRetailer]);

  const fetchArticles = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-articles`, {
        suppliercode: selectedSupplier,
        retailercode: selectedRetailer,
      });
      setArticles(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching articles:", error);
      setLoading(false);
    }
  };

  const toggleActiveYn = async (articlecode, activeyn) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/toggle-article`, {
        suppliercode: selectedSupplier,
        retailercode: selectedRetailer,
        articlecode,
        activeyn: !activeyn,
      });
      setArticles((prevArticles) => prevArticles.map((article) => (article.articlecode === articlecode ? { ...article, activeyn: !activeyn } : article)));
    } catch (error) {
      console.error("Error updating article status:", error);
    }
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
    setEditRowData({
      brand: articles[index].brand,
      articleunitcost: articles[index].articleunitcost,
      articledescription: articles[index].articledescription,
    });
  };

  const handleSave = async () => {
    const { brand, articleunitcost, articledescription } = editRowData;
    const articlecode = articles[editRowIndex].articlecode;
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-article`, {
        brand,
        articleunitcost,
        articledescription,
        suppliercode: selectedSupplier,
        retailercode: selectedRetailer,
        articlecode,
      });
      toast({
        title: "Article updated",
        description: "The article has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditRowIndex(null);
      fetchArticles();
    } catch (error) {
      toast({
        title: "Failed to update article",
        description: "An error occurred while updating the article.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box overflowX="auto" mt={16} maxHeight="900px" overflowY="auto" maxWidth="97vw">
      <Table variant="striped" size="sm" colorScheme="custom" border="1px" borderColor="gray.200" borderRadius="md">
        <Thead>
          <Tr>
            <Th>Article Code</Th>
            <Th>Description</Th>
            <Th>Unit Cost</Th>
            <Th>Brand</Th>
            <Th>Created Date</Th>
            <Th>Active</Th>
            <Th>Edit</Th>
          </Tr>
        </Thead>
        <Tbody>
          {articles.map((article, index) => (
            <Tr key={article.articlecode}>
              <Td>{article.articlecode}</Td>
              <Td>{editRowIndex === index ? <Input value={editRowData.articledescription} onChange={(e) => setEditRowData({ ...editRowData, articledescription: e.target.value })} /> : article.articledescription}</Td>
              <Td>{editRowIndex === index ? <Input value={editRowData.articleunitcost} onChange={(e) => setEditRowData({ ...editRowData, articleunitcost: e.target.value })} /> : article.articleunitcost}</Td>
              <Td>{editRowIndex === index ? <Input value={editRowData.brand} onChange={(e) => setEditRowData({ ...editRowData, brand: e.target.value })} /> : article.brand}</Td>
              <Td>{new Date(article.createddate).toLocaleDateString()}</Td>
              <Td>
                <Switch isChecked={article.activeyn} onChange={() => toggleActiveYn(article.articlecode, article.activeyn)} />
              </Td>
              <Td>{editRowIndex === index ? <IconButton icon={<FaSave />} onClick={handleSave} colorScheme="green" variant="ghost" /> : <IconButton icon={<FaEdit />} onClick={() => handleEdit(index)} colorScheme="blue" variant="ghost" />}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Articles;
