import React, { useState, useEffect } from "react";
//import axios from "axios";
import { Box, Heading, Flex } from "@chakra-ui/react";
import { useSupplierRetailer } from "../../wrappers/SupplierRetailerContext";
import Table1 from "./components/Table1";

const Margin = () => {
  const { selectedRetailer, selectedSupplier } = useSupplierRetailer();
  const [loading, setLoading] = useState(false);

  return (
    <Box overflowX="hidden" overflowY="hidden">
      <Heading size="lg" mb={4}>
        Margin Report
      </Heading>
      {loading ? (
        <Box>Loading...</Box>
      ) : (
        <>
          <Box >
          <Table1 supplier={selectedSupplier} retailer={selectedRetailer} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Margin;
