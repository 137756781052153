import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, FormControl, FormLabel, Select, useToast, Flex, IconButton } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const EditPageAccess = () => {
  const { userid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedPages, setSelectedPages] = useState([]);
  const [availablePages, setAvailablePages] = useState([]);
  const [user, setUser] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-user-pageAccess`, { userid });
        const { page_access, pages, user } = response.data.data;
        setUser(user);
        setSelectedPages(page_access.map((item) => item.page_name));
        setAvailablePages(pages);
      } catch (error) {
        toast({
          title: "Failed to load page access data",
          description: "An error occurred while loading data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, [userid, toast]);

  const handlePageToggle = (page) => {
    setSelectedPages((prev) => (prev.includes(page) ? prev.filter((item) => item !== page) : [...prev, page]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/upd-user-pageAccess`, {
        userid,
        pages: selectedPages,
      });
      toast({
        title: response.data.title,
        description: response.data.description,
        status: response.data.status,
        duration: 3000,
        isClosable: true,
      });
      navigate("/users");
    } catch (error) {
      console.log({ error });
      toast({
        title: "Failed to update page access",
        description: "An error occurred while updating page access",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box width="500px" margin="50px auto" textAlign="center" padding="4" boxShadow="md" borderRadius="md" backgroundColor="white">
      <Flex justify="space-between" mb={4} align="center">
        <IconButton icon={<FaArrowLeft />} colorScheme="gray" onClick={() => navigate("/users")} aria-label="Go back" />
      </Flex>
      <form onSubmit={handleSubmit}>
        <FormControl mt="2">
          <FormLabel textAlign="center" width="100%">
            {user.username}
          </FormLabel>
          <FormLabel>Page Access</FormLabel>

          <Box>
            {availablePages.map((page) => (
              <Button key={page} variant={selectedPages.includes(page) ? "solid" : "outline"} colorScheme={selectedPages.includes(page) ? "green" : "gray"} mr={2} mb={2} onClick={() => handlePageToggle(page)}>
                {page}
              </Button>
            ))}
          </Box>
        </FormControl>

        <Box mt={4}>
          <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
            Submit
          </Button>
          <Button onClick={() => navigate("/users")} colorScheme="gray" ml={4}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditPageAccess;
